import React, { Component } from "react";
//import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
/*import Button from '@material-ui/core/Button';*/
import LockIcon from '@material-ui/icons/Lock'
import MenuList from "@material-ui/core/MenuList";
import Backdrop from "../../UI/Backdrop/Backdrop";
import IconButton from "@material-ui/core/IconButton";
import HamburgerMenu from "@material-ui/icons/Menu";
import Hoc from '../../../hoc/hoc';

import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Tooltip from '@material-ui/core/Tooltip'

import ReactGA from "react-ga";

import * as actions from "../../../store/actions/index";

import { connect } from "react-redux";

import classes from "./SideDrawer.module.css";

const mLinks = {
  one: "nagrade",
  two: "kako-da-ucestvujem",
  three: "birra-moretti-nagradjuje",
  four: "dobitnici",
  five: "kontakt-i-pravila",
  six: "profil",
};

class SideDrawer extends Component {

	state = {
		anchorEl: null
	}

  doClick = (nm) => {
    if (nm === "profile") {
      //console.log(this.props);
      this.props.history.push("/profil");
      this.props.onUpdatePath("/profil");
    } else if (nm === "home") {
      this.props.history.push("/");
      this.props.onUpdatePath("/");
    } else {
      this.props.history.push("/" + mLinks[nm]);
      this.props.onUpdatePath("/" + mLinks[nm]);
    }

    ReactGA.event({
      category: "Navigation",
      action: "Clicked menu link:" + nm,
    });
    this.props.onUpdateSideDrawer(false);
  };


	// local state user menu
	handleUserMenuOpen = event => {
		this.setState({ anchorEl: event.currentTarget });
	}

	handleUserMenuClose = () => {
		this.setState({ anchorEl: null });
	}

  render() {

		let profileIcon;
		let profileMenu;

    const { anchorEl } = this.state;

    if (this.props.isAuthenticated) {
			if (this.props.avatar) {
				profileIcon = <img src={'data:image/png;base64,' + this.props.avatar} className={classes.avatarImage} alt="" />
			} else {
				profileIcon = <AccountCircle />;
			}

			profileMenu = (
				<Menu
					id="user-menu"
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={this.handleUserMenuClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right'
					}}
					getContentAnchorEl={null}
				>
					<MenuItem onClick={() => {
						//this.props.onUpdateModal(true,'profile');
						this.doClick('profile');
						this.handleUserMenuClose();
					}}>
						Moj profil
					</MenuItem>
					<MenuItem onClick={() => {
						this.props.onLogout();
						this.handleUserMenuClose();
					}}>
						Odjava
					</MenuItem>
				</Menu>
			)
		} else {
			profileIcon = <LockIcon />;
			profileMenu = null;
		}



    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (this.props.sideState) {
      attachedClasses = [classes.SideDrawer, classes.Open];
    }

    if (
      this.props.curPath === "/verifyAccount" ||
      this.props.curPath === "/forgotPassword"
    ) {
      return null;
    }

    let menulist = null;
    menulist = (
      <MenuList className={classes.SideMenu}>
        <MenuItem
          onClick={() => {
            this.doClick("home");
          }}
        >
          Početna
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.doClick("one");
          }}
        >
          {this.props.menuLinks[0]}
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.doClick("two");
          }}
        >
          {this.props.menuLinks[1]}
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.doClick("three");
          }}
        >
          {this.props.menuLinks[2]}
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.doClick("four");
          }}
        >
          {this.props.menuLinks[3]}
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.doClick("five");
          }}
        >
          {this.props.menuLinks[4]}
        </MenuItem>
        <MenuItem
          //onClick={ () => { this.doClick('six') } }
          onClick={() => {
            if (this.props.isAuthenticated) {
              //this.props.onUpdateModal(true,'profile');
              this.doClick("profile");
            } else {
              this.props.onUpdateModal(true, "login");
            }
          }}
        >
          {this.props.isAuthenticated ? this.props.menuLinks[5] : "Prijava"}
        </MenuItem>
      </MenuList>
    );

    return (
      <Hoc>
        <div className={classes.onlyMobile}>
          <IconButton
            className={classes.openMenuButton}
            variant="fab"
            aria-label="More"
            aria-haspopup="true"
            onClick={() => this.props.onUpdateSideDrawer(!this.props.sideState)}
          >
            <HamburgerMenu />
          </IconButton>
        </div>

        <div className={classes.sideDrawerVisible}>
          <Backdrop
            show={this.props.sideState}
            clicked={() => this.props.onUpdateSideDrawer(false)}
          ></Backdrop>
          <Paper className={attachedClasses.join(" ")}>
            {/*
          <Button
            className={classes.logoHolder}
            onClick={() => { this.doClick('home') }}
          >
              <img className={classes.logo} src="/birra-moretti-logo.png" alt="" />
          </Button>
        */}
            {menulist}
          </Paper>
        </div>

        <Hoc>
							<Tooltip id="tooltip-icon3"
								title={this.props.isAuthenticated ? "Moj profil" : "Prijava"}
								placement="left"
							>
								<>
								<IconButton
									aria-owns={anchorEl ? 'user-menu' : null}
									aria-haspopup="true"
									className={classes.loginButton}
									name={this.props.isAuthenticated ? "profile" : "signin"}
									aria-label={this.props.isAuthenticated ? 'Moj profil' : 'Prijava'}
									color='inherit'
									rel='noopener'
									onClick={(e) => {
										if (this.props.isAuthenticated) {
											this.handleUserMenuOpen(e);
										} else {
											this.props.onUpdateModal(true, 'login');
										}
									}
									}
								>
									{profileIcon}
								</IconButton>
								</>
							</Tooltip>
							{profileMenu}
						</Hoc>

      </Hoc>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    curPath: state.main.currentPath,
    isAuthenticated: state.auth.token !== null,
    sideState: state.main.showSideDrawer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateSideDrawer: (sideState) =>
      dispatch(actions.changeSidedrawer(sideState)),
    onUpdateLPElement: (lpel) =>
      dispatch(actions.changeLandingPageElement(lpel)),
    onUpdateLPPosition: (lpPos) =>
      dispatch(actions.changeLandingPagePosition(lpPos)),
    onUpdateModal: (modState, modWhat) =>
      dispatch(actions.changeModal(modState, modWhat)),
    onUpdatePath: (newpath) => dispatch(actions.changePath(newpath)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideDrawer)
);
