import React, { Component } from "react";
import classes from "./Nagrade.module.css";

import Spinner from "../../components/UI/LoadingSpinner";

import Hoc from "../../hoc/hoc";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import * as actions from "../../store/actions/index";

class Nagrade extends Component {
  componentWillMount() {
    this.props.spinnerChange(true);
    if (this.props.curPath !== "/nagrade") {
      this.props.onUpdatePath("/nagrade");
    }
  }

  componentDidMount() {
    this.props.onRdyLanding();
    this.props.spinnerChange(false);
  }

  render() {
    let spin = null;
    if (this.props.spin) {
      spin = <Spinner />;
    }

    return (
      <Hoc>
        <div className={classes.container} location={this.props.curPath}>
          <section id="container">
            <div className={classes.mainSplash}>
              <div className={classes.titleHolder}>
                <h1>Nagrade</h1>

                <br/><br/>
								<h2>5 <small>x</small> vaučer 100.000 RSD</h2>
                <h4>za kupovinu u Matis maloprodajnim objektima</h4>
								<br/>
								<br/>
								<h3>
									10 <small>x</small> vaučer 50.000 RSD 
								</h3>
                <h4>za kupovinu u Matis maloprodajnim objektima</h4>
								<br/>
								<h3>
									10 <small>x</small> vaučer 12.000 RSD 
								</h3>
                <h4>za kupovinu u Mercator maloprodajnim objektima (Mercator, Roda i Idea).</h4>
								<br/>
								<h3>
									100 <small>x</small> Birra Moretti mlin za biber
								</h3>
								<h3>
									300 <small>x</small> Birra Moretti tanjir za pastu
								</h3>
								<h3>
									500 <small>x</small> Birra Moretti podmetač za hranu
								</h3>
              </div>
              <img src="/nagrade.png" alt="" />
              <br/><br/><br/><br/><br/><br/>
            </div>
          </section>
        </div>
        <div className={classes.SpinnerHolder}>{spin}</div>
      </Hoc>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rdy: state.main.landingPageReady,
    spin: state.main.spinnerState,
    lpElement: state.main.landingPageElement,
    curPath: state.main.currentPath,
    lp: state.main.landingPagePosition,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRdyLanding: () => dispatch(actions.landingpageReady()),
    spinnerChange: (spin) => dispatch(actions.spinnerState(spin)),
    onUpdatePath: (newpath) => dispatch(actions.changePath(newpath)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Nagrade)
);
