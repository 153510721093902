import React, { Component } from "react";
import classes from "./LandingPage.module.css";

import Spinner from "../../components/UI/LoadingSpinner";

import Hoc from "../../hoc/hoc";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import * as actions from "../../store/actions/index";

class LandingPage extends Component {
  componentWillMount() {
    this.props.spinnerChange(true);
  }

  componentDidMount() {
    this.props.onRdyLanding();
    this.props.spinnerChange(false);
  }

  render() {
    let spin = null;
    if (this.props.spin) {
      spin = <Spinner />;
    }

    return (
      <Hoc>
        <div className={classes.container} location={this.props.curPath}>
          <section id="container">
            <div className={classes.mainSplash}>
              <div className={classes.mainSplashText}>
               <img src="/bm-main-headline.png" alt="Birra Moretti te nagrađuje" className={classes.headlineImage} />
                  <ol>
                    <li>
                      Kupi 3 Birra Moretti piva
                    </li>
                    <li>
                      Uloguj se na www.birramoretti.com
                    </li>
                    <li>
                      Odgovori tačno na pitanje, sakupi poene i možeš osvojiti sjajne nagrade
                    </li>
                  </ol>
                  <img
                    className={classes.nagrade}
                    src="/nagrade-vizual.png"
                    alt="nagrade"
                  />
                  <img
                    className={classes.halfseal}
                    src="/half-seal.png"
                    alt="Birra Moretti"
                  />
                </div>
                <img
                  className={classes.mainSplashImage}
                  src="/main-splash.jpg"
                  alt=""
                />
                <img className={classes.leftSeal} src="/left-seal.png" alt="" />
                <img className={classes.legalSeal} src="/legal.png" alt="" />
                <img className={classes.shopsD} src="/shops-h.png" alt="" />
                <div className={classes.mainSplashBgnd}></div>
            </div>
			<div className={classes.legalSealMobile}>
				      <img src="/legal.png" alt="" />
            	<img className={classes.seals} src="/seals-vertical.png" alt="" />
            	<img className={classes.shopsM} src="/shops-v.png" alt="" />
			</div>
            <img className={classes.seals} src="/seals.png" alt="" />
            <div className={classes.trajanje}>
              <p>Period trajanja aktivacije: 13.11. - 17.12.2023. godine.</p>
              <p className={classes.smaller}>Slika poklona ne mora odgovarati stvarnom poklonu. U nagradnom konkursu učestvuju sve limenke i boce 
Birra Moretti brenda koje se mogu kupiti u Idea, Roda i 
Mercator prodavnicama u kojima se sprovodi aktivacija.
Više informacija na www.birramoretti.com
Fiskalne račune je potrebno čuvati do kraja aktivacije i dodele 
poklona.</p>
            </div>
          </section>
        </div>
        <div className={classes.SpinnerHolder}>{spin}</div>
      </Hoc>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rdy: state.main.landingPageReady,
    spin: state.main.spinnerState,
    lpElement: state.main.landingPageElement,
    curPath: state.main.currentPath,
    lp: state.main.landingPagePosition,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRdyLanding: () => dispatch(actions.landingpageReady()),
    spinnerChange: (spin) => dispatch(actions.spinnerState(spin)),
    onUpdateLPPosition: (lpPos) =>
      dispatch(actions.changeLandingPagePosition(lpPos)),
    onUpdateLPElement: (lpel) =>
      dispatch(actions.changeLandingPageElement(lpel)),
    onUpdatePath: (newpath) => dispatch(actions.changePath(newpath)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LandingPage)
);
