import React from 'react';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Clear';
import classes from './Modal.module.css';
import Hoc from '../../../hoc/hoc';
import Backdrop from '../Backdrop/Backdrop';

const modal = (props) => (
	<Hoc>
		<Backdrop show={props.show} clicked={props.modalClosed} />
		<div
			style={{
				//transform: props.show ? 'translateY(calc(-50% + 50px))' : 'translateY(-200vh)',
				transform: props.show ? 'scale(1) translateY(-50%)' : 'scale(0) translateY(-50%)',
				opacity: props.show ? '1' : '0'
			}}
			className={classes.Modal}
		>
		<Paper 
			elevation={0}
		>
			{props.children}
			<div className={classes.closeButton}>
				<IconButton aria-label="Delete"
					onClick={props.modalClosed}
				>
					<CloseIcon />
				</IconButton>
			</div>
		</Paper>
		</div>
	</Hoc>
);

export default modal;