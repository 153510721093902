import * as actionTypes from './actionTypes';
// import axios from 'axios';
import axiosSetup from '../../axios-setup';
// import Moment from 'moment';

export const authStart = () => {
	return {
		type: actionTypes.AUTH_START
	};
};

export const authSuccess = (token, userId) => {
	//console.log(token,userId);
	return {
		type: actionTypes.AUTH_SUCCESS,
		idToken: token,
		userId: userId
	};
};

export const resetErrorState = () => {
	return {
		type: actionTypes.RESET_ERROR_STATE
	};
};

export const auth = (email, password, typeOfForm, name, phone, jmbg, address, city, zip) => {
	return dispatch => {
		dispatch(authStart());
		let authData = {
			email,
			password,
			name,
			phone,
			jmbg, 
			address,
			city,
			zip
		}
		let url = '';

		if (typeOfForm==='login') {
			authData=null;
			authData = {
				email,
				password
			};			
			url = '/users/login'
		} else if (typeOfForm==='signup') {
			url = '/users';
		} else if (typeOfForm==='forgot') {
			url = '/users/forgotPassword';
			authData=null;
			authData = {
				email
			}
		}

		if (typeOfForm==='signup') {
			
			// REGISER USER
			axiosSetup.post(url, authData)
			.then(response => {
				if (response.status===201) {
					//console.log("USER CREATED!!!!!!!!!!")
					//dispatch(loginAndSendVerificationEmail(email,true));
					dispatch(loginWarning(true,'user_created'));
					return(true);
				}
				return(true);
			})
			.catch( err => {
				console.log(err);
				dispatch(authFail({message: err.response.data.error}));
			});

		} else if (typeOfForm==='login') {

			axiosSetup.post(url, authData)
			.then( response => {
				const userData = response.data.user;
				const token = response.data.token;

				// parse token object: _id, issuedat and expires values
				const tokenObject = JSON.parse( atob(token.split('.')[1]) );
				const expiresIn = (tokenObject.exp - tokenObject.iat);
				const expirationDate = new Date(tokenObject.exp*1000);

				localStorage.setItem('token', token);
				localStorage.setItem('expirationDate', expirationDate);
				localStorage.setItem('userId', userData._id);

				dispatch(authSuccess(token, tokenObject._id));
				dispatch(authCheckState());
				dispatch(checkAuthTimeout(expiresIn));				
				return true;
			})
			.catch (err => {
				if (err.response.data.error === 'email_not_verified') {
					dispatch(loginWarning(true,err.response.data.error));	
				} else {
					dispatch(authFail({message: err.response.data.error}));
				}
				return;
			})
		} else if (typeOfForm==='forgot') {
			
			axiosSetup.post(url, authData)
			.then(response => {
				//console.log(response.data.error);
				if (response.data.error === 'EMAIL_RESET_SENT') {
					dispatch(authFail({message:response.data.error}));
				}
			})
			.catch( (err) => {
				//console.log(err);
				dispatch(authFail({message: err.response.data.error}));
			});
		}


	};
};

export const verifyAccountStart = (hash) => {
	//console.log('hash', hash);
	return dispatch => {
		dispatch(authStart());
		axiosSetup.get('/users/verify/'+hash)
		.then( response => {
			dispatch(verifyAccountMessage(response.data))
		})
		.catch(err => {
			dispatch(verifyAccountMessage(err.response.data))
		})
	}
}

export const forgotPasswordStart = (hash, pass) => {

	let data = null;
	if (pass) {
		data = {
			password: pass
		}
	}

	return dispatch => {
		dispatch(authStart());
		axiosSetup.post('/users/forgot/'+hash, data)
		.then( response => {
			dispatch(forgotPasswordMessage(response.data.status))
		})
		.catch(err => {
			//console.log('err: ',err.response.data.status);
			dispatch(forgotPasswordMessage(err.response.data.status))
		})
	}
}


export const loginAndSendVerificationEmail = (email, password, sw) => {
	return dispatch => {
		dispatch(authStart());
		
		axiosSetup.post('/users/verify', {email, password})
		.then( response => {
			dispatch(loginWarning(true,'email_verification_sent'));
			dispatch(authFail({message:'EMAIL_VERIFY_SENT'}))
			return true;
		})
		.catch(err => {
			console.log(err);
			dispatch(authFail({message: err.response.data.error}));
		});
	}
}


export const startLogout = (authToken, userID) => {
	return dispatch => {
		dispatch(getProfileStart());
		axiosSetup.defaults.headers.common = {'Authorization': "Bearer " + authToken};
		axiosSetup.post('/users/logout')
		.then( res => {
			if (res.data.message==='LOGGED_OUT') {
				dispatch(logout());
			}
		} )
		.catch ( err => {
			dispatch(logout(err));
		})
	}
}

export const logout = () => {
	localStorage.removeItem('token');
	localStorage.removeItem('expirationDate');
	localStorage.removeItem('userId');	
	localStorage.removeItem('avatar');	
	return {
		type: actionTypes.AUTH_LOGOUT
	};
};

export const checkAuthTimeout = (expirationTime) => {
	//console.log('expiration time: ', expirationTime)
    return dispatch => {
        setTimeout(() => {
            dispatch(startLogout());
		}, expirationTime * 1000);
		
		if (expirationTime > 300) {
			setTimeout(()=> {
				dispatch(logoutWarning(true));
				// 5 minutes
			}, (expirationTime-300)*1000);
		} else {
			dispatch(logoutWarning(true));
		}
    };
};

export const authCheckState = () => {
    return dispatch => {
		const token = localStorage.getItem('token');
		//console.log(token);
        if (!token) {
            dispatch(startLogout());
        } else {
			const expirationDate = new Date(localStorage.getItem('expirationDate'));
			//console.log(expirationDate, new Date());
            if (expirationDate <= new Date()) {
                dispatch(startLogout());
            } else {
				const userId = localStorage.getItem('userId');
				//console.log(userId);
				dispatch(authSuccess(token, userId));
				var expTime = (expirationDate.getTime() - new Date().getTime()) / 1000;
				dispatch(checkAuthTimeout(expTime));

				// get avatar either from local storage or FB
				const avatar = localStorage.getItem('avatar');
				//console.log('checking local storage: ' + avatar);
				if (avatar) {
					//console.log('local storage avatar found');
					dispatch(setProfileAvatar(avatar))
				} else {
					//console.log('no local storage avatar found');
					dispatch(getAvatar(token, true));
				}
            }
        }
    };
};

export const logoutWarning = ( warning ) => {
	return {
		type: actionTypes.SET_LOGOUT_WARNING,
		warn: warning
	}
}

export const setPrevResults = (results) => {
	return {
		type:actionTypes.SET_PREVIOUS_RESULTS,
		res:results
	}
}

export const getProfileSuccess = (profile) => {
	return {
		type: actionTypes.GET_PROFILE_SUCCESS,
		profile: profile
	}
};

export const getProfileFail = (error) => {
	return {
		type: actionTypes.GET_PROFILE_FAIL,
		error: error
	};
};

export const getProfileStart = () => {
	return {
		type: actionTypes.GET_PROFILE_START
	}
}

export const getProfile = (authToken, userID) => {
	axiosSetup.defaults.headers.common = {'Authorization': "Bearer " + authToken};
	return dispatch => {
		dispatch(getProfileStart());
		/*
		let previousResults;
		axiosSetup.get('/users/previousResults')
			.then( res => {
				previousResults = res.data.prevWeeks;
				dispatch(setPrevResults(previousResults))
			})
			.catch(err => {
				console.log(err);
			})
		*/
		axiosSetup.get('/users/me')
			.then( res => {
				const profileData = {};
				for (let key in res.data ) {
					profileData[key] = res.data[key];
				}
				/*
				if (previousResults) {
					profileData.prev = previousResults;
				}
				*/
				dispatch(getProfileSuccess(profileData));
				const avatar = localStorage.getItem('avatar');
				//console.log('checking local storage: ' + avatar);
				if (avatar) {
					dispatch(setProfileAvatar(avatar));
				} else {
					dispatch(getAvatar(authToken));
				}
			} )
			.catch ( err => {
				dispatch(getProfileFail(err));
			})
	}
}

export const setAvatar = (imgData,uid,authToken) => {
	//localStorage.setItem('avatar', imgData);
	return dispatch => {
		dispatch(authStart());

		let formData = new FormData(); 
		formData.append("file", imgData); // storageRef = storage.ref(uid).child('avatar.png');
		axiosSetup.defaults.headers.common = {
			'Authorization': "Bearer " + authToken,
			'Content-Type': 'multipart/form-data'
		};
		
		axiosSetup.post('/users/me/avatar', formData)
		.then( res => {
			//console.log(res.data.image);
			localStorage.setItem('avatar', res.data.image); // set image locally
			dispatch(setProfileAvatar(res.data.image)); // set it to store for this session
			//dispatch(getAvatar(uid,true));
			return true;
		})
		.catch( err => {
			console.log(err);
		})
	}
}

export const getAvatar = (authToken,me) => {
	axiosSetup.defaults.headers.common = {'Authorization': "Bearer " + authToken};
	return dispatch => {
		let avatarData;
		axiosSetup.get('/users/me/avatar')
		.then( res => {
			//console.log(res.data);
			avatarData = res.data;
			if (me) {
				localStorage.setItem('avatar', avatarData);
				dispatch(setProfileAvatar(avatarData));
				return true;
			} else {
				return (avatarData);
			}				
		})
		.catch( err=> {
			console.log(err);
		})
	}
}

export const removeAvatar = (uid,authToken) => {
	axiosSetup.defaults.headers.common = {'Authorization': "Bearer " + authToken};
	return dispatch => {
		dispatch(authStart());
		axiosSetup.delete('/users/me/avatar')
		.then( url => {
			dispatch(unsetProfileAvatar());
			localStorage.removeItem('avatar');
		})
		.catch ( (err) => {
			//console.log('ERROR: '+err);
			dispatch(unsetProfileAvatar());
			localStorage.removeItem('avatar');
		})
	}
}

export const setProfileAvatar = (urlData) => {
	return {
		type: actionTypes.SET_PROFILE_AVATAR,
		url: urlData
	}
}

export const unsetProfileAvatar = () => {
	return {
		type: actionTypes.DELETE_PROFILE_AVATAR,
	}
}
/*
export const saveProfileAvatar = (url,uid,token) => {
	// saving to users FB branch
	return dispatch => {
		axiosSetup.patch('/users/'+uid+'.json?auth='+token,{
			'avatar': url
		})
		.then(()=> {
			//console.log('avatar set');
		})
		.catch((err)=> {
			console.log(err);
		});

		axiosSetup.patch('/usersPublic/'+uid+'.json?auth='+token,{
			'avatar': url
		})
		.then(()=> {
			//console.log('avatar public set');
		})
		.catch((err)=> {
			console.log(err);
		});

	}
}

export const getAvatarLinkFromFB = (token,uid) => {
	return dispatch => {
		return;
		axiosSetup.get('/users/'+uid+'.json?auth='+token)
		.then(response => {
			//console.log('get url from firebase: '+ response.data.avatar);
			if (response.data.avatar===undefined) {
				return true;
			} else {
				dispatch(setProfileAvatar(response.data.avatar));
				return true;

				axios.get(response.data.avatar, {
					responseType: 'arraybuffer',
					crossDomain: true
    			}).then(response => {
					const bin = Buffer.from(response.data, 'binary').toString('base64');
					console.log(bin);

				})
				.catch((err) => {
					//console.log('error getting binary image data from url: '+err)
				})

			}
			
		})
		.catch((err)=> {
			//console.log('no avatar in database: ' + err);
		});
	}
}
*/
export const deleteProfile = (authToken, uid) => {
	//console.log('auth on delete:', authToken);
	axiosSetup.defaults.headers.common = {'Authorization': "Bearer " + authToken};
	return dispatch => {
		dispatch(authStart());
		axiosSetup.delete('/users/me')
			.then( res => {
				if (res.data._id === uid) {
					dispatch(doDeleteProfile());
					dispatch(logout());
				}
			} )
			.catch ( err => {
				console.log('ERROR_DELETING_USER_DATA:',err);
			})
	}

	/*
	return dispatch => {

		const url = 'https://us-central1-durex-360.cloudfunctions.net/deleteUserData';
		/*
		const deleteUrl = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/deleteAccount?key='+apiKey;
		const payload = {
			idToken: token
		}


		dispatch(authStart());
		//dispatch(removeAvatar(uid,token));

		// switch to firebase server functions
		axios.post(url, {
			uid:uid,
			tel:ph
		})
		.then((response) => {
			//console.log(response.data);
			if (response.data['message'] === 'USER_DATA_DELETED') {
				dispatch(logout());
				dispatch(doDeleteProfile());
			}
		})
		.catch((err)=> {
			console.log('ERROR_DELETING_USER_DATA:',err);
		});
	}
			*/
}

export const doDeleteProfile = () => {
	return {
		type: actionTypes.DELETE_PROFILE
	}	
}


export const loginWarning = (warning,warningType) => {
	//console.log('reporting from loginWarning action: ',warning,warningType);
	return {
		type: actionTypes.SET_LOGIN_WARNING,
		warning: warning,
		warningType: warningType
	}
}

export const authFail = (error) => {
	//console.log('er: '+JSON.stringify(error));
	return {
		type:actionTypes.AUTH_FAIL,
		error: error
	};
};

export const verifyAccountMessage = ( message ) => {
	return {
		type: actionTypes.VERIFY_ACCOUNT_MESSAGE,
		message
	}
}

export const forgotPasswordMessage = ( message ) => {
	return {
		type: actionTypes.FORGOT_PASSWORD_MESSAGE,
		message
	}
}
