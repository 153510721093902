import React, { Component } from 'react';

import classes from './Layout.module.css';

import Hoc from './../../hoc/hoc';
//import Toolbar from '../Navigation/Toolbar/Toolbar'
import SideDrawer from '../Navigation/SideDrawer/SideDrawer'
import Modal from '../UI/Modal/Modal';
import BackToTop from '../UI/BackToTop/BackToTop';

import {withRouter} from 'react-router-dom';
import Login from '../../containers/Login/Login';
import DeleteConfirmation from '../../containers/DeleteConfirmation/DeleteConfirmation';
import LogoutWarning from '../../containers/LogoutWarning/LogoutWarning';
// import Profile from '../../containers/Profile/Profile'

/* redux stuff*/
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';


class Layout extends Component {

	state = {
		menuLinks : [
			'Nagrade',
			'Kako da učestvujem?',
			'Birra Moretti nagrađuje',
			'Dobitnici',
			'Kontakt i pravila',
			'Moj profil'
		],
	};


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
            this.cancelModal()
		}
	}
	
	componentWillReceiveProps (newProps) {
		if (newProps.logoutWarning && newProps.logoutWarning!==this.props.logoutWarning) {
			this.props.onUpdateModal(true,'sessionExpiration');
		}
	}

	cancelModal = () => {
		this.props.onUpdateModal(false,'');
	}

	activateModal = (obj) => {
		this.props.onUpdateModal(true,obj);
	}

	returnLandingPageToTop = () => {
		this.props.onUpdateLPElement('zero');
	}


	render () {

		let showInModal = <Login/>;
		//console.log(this.props.showInModal);
		if (this.props.modwhat==='deleteConfirm') {
			showInModal = <DeleteConfirmation />
		} else if (this.props.modwhat==='sessionExpiration') {
			showInModal = <LogoutWarning />
		}

		return (
			<Hoc className={classes.bl}>
				<Modal show={this.props.showmod} modalClosed={this.cancelModal}>
					{showInModal}
				</Modal>
				<BackToTop 
					position={this.props.lpPosition}
					returnToTop={this.returnLandingPageToTop}
				/>
				<SideDrawer 
					menuLinks={this.state.menuLinks}
				/>
				{/*
				<Toolbar 
					menuLinks={this.state.menuLinks}
				/>
				*/}
				<main>
					{this.props.children}
				</main>
			</Hoc>
		);
	}
}


const mapStateToProps = state => {
	return {
		curPath: state.main.currentPath,		
		showmod: state.main.showModal,
		modwhat: state.main.showInModal,
		lpPosition: state.main.landingPagePosition,
		sideState: state.main.showSideDrawer,
		lpElement: state.main.landingPageElement,
		isAuthenticated: state.auth.token !== null,
		logoutWarning: state.auth.logoutWarning,
	};
}

const mapDispatchToProps = dispatch => {
	return {
		onUpdateModal: (modState, modWhat) => dispatch( actions.changeModal(modState, modWhat) ),
		onUpdateLPPosition: (lpPos) => dispatch ( actions.changeLandingPagePosition(lpPos) ),
		onUpdateSideDrawer: (sideState) => dispatch ( actions.changeSidedrawer(sideState) ),
		onUpdateLPElement: (lpel) => dispatch ( actions.changeLandingPageElement(lpel) ),
		onLogout: () => dispatch (actions.logout() )
	}
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));