import React, { Component } from 'react';
import classes from './Dobitnici.module.css';

import Spinner from '../../components/UI/LoadingSpinner'
import Grid from '@material-ui/core/Grid';

import axiosSetup from '../../axios-setup';

import Hoc from '../../hoc/hoc';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

class Dobitnici extends Component {

	state = {
		winnerList: null,
	}


	componentWillMount() {
        this.props.spinnerChange(true);
        if (this.props.curPath!=='/dobitnici') {
            this.props.onUpdatePath('/dobitnici');
        }
	}

	componentDidMount() {
		this.props.onRdyLanding();
		this.props.spinnerChange(false);
		axiosSetup.get('/winnerList')
		.then( response => {
			this.setState(
				{
					...this.state,
					winnerList: response.data
				}
			)
		})
		.catch ( err => {
			console.log(err.response.data)
		})

	};

	render() {

		let spin = null;
		if (this.props.spin) {
			spin = <Spinner />
		}

		let winnersTable = [];
		let winnersTablePrep ='';

		if (this.state.winnerList) {
			if (typeof(this.state.winnerList)!=='object') {
				winnersTablePrep = <tr><td style={{textAlign:"center", border:"none"}}>{this.state.winnerList}</td></tr>
			} else {
				winnersTablePrep = this.state.winnerList.map((el,ind) => {
					const hgh = ind%2 ? {backgroundColor: 'rgba(255,255,255,0.4)'} : null;
					return (<tr key={ind} style={hgh}><td style={{textAlign:"center"}}>{ind+1}.</td><td style={{textAlign:"center"}}>{el.name}</td><td style={{textAlign:"center"}}>{el.city}</td><td style={{textAlign:"center"}}>{el.prize}</td></tr>);
				});
				let header = (<tr key={999} style={{backgroundColor:'rgb(125 21 37)', color:'#fff', fontSize:'18px'}}><th>Red. br.</th><th>Broj telefona</th><th>Grad</th><th>Nagrada</th></tr>);
				winnersTablePrep.unshift(header);
			}

			winnersTable = (
				<>
				<Grid key={0} container spacing={0} className={classes.grid}>
					<Grid item xs></Grid>
					<Grid item xs={11} md={8}>
						<table className={classes.winnerTable}><tbody>{winnersTablePrep}</tbody></table>
					</Grid>
					<Grid item xs></Grid>
					<br/><br/>
				</Grid>
				</>
			)
		}

		return (
			<Hoc>
				<div className={classes.container} style={{height:"550px", overflowY:"auto"}} location={this.props.curPath}>
					<section id="container">
					<br/><br/><br/>
						<h1>Dobitnici</h1>
						{winnersTable}
						{(typeof(this.state.winnerList)!=='object')
						? <p className={classes.pstyled}>Prilikom svake prijave na stranici „Moj profil“ moći ćete da vidite na kojoj ste poziciji na Rang listi, ukupan broj osvojenih bodova kao i koliko bodova ima prvi sledeći bolje plasirani Učesnik na Rang listi.</p>
						: null
						}
					<br/><br/><br/>
					</section>
				</div>
				<div className={classes.SpinnerHolder}>
					{spin}
				</div>
			</Hoc >
		);
	}
};

const mapStateToProps = state => {
	return {
		rdy: state.main.landingPageReady,
		spin: state.main.spinnerState,
		lpElement: state.main.landingPageElement,
		curPath: state.main.currentPath,
		lp: state.main.landingPagePosition
	};
}

const mapDispatchToProps = dispatch => {
	return {
		onRdyLanding: () => dispatch(actions.landingpageReady()),
		spinnerChange: (spin) => dispatch(actions.spinnerState(spin)),
		onUpdatePath: (newpath) => dispatch(actions.changePath(newpath)),
	}
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dobitnici));