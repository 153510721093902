import React from 'react';
import Fab from '@material-ui/core/Fab';
import ArrowUp from '@material-ui/icons/ArrowUpward';

import classes from './BackToTop.module.css';

const backToTop = (props) => (
	<Fab 
		data-pos={props.position}
		className={props.position > 0 ? classes.BackToTop + ' ' + classes.show : classes.BackToTop + ' ' + classes.hide}
		variant="round"
		color="secondary"
		aria-label="Back to top"
		onClick={props.returnToTop}
		>
	  	<ArrowUp/>
	</Fab>

);

export default backToTop;