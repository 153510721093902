import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index'

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Reaptcha from 'reaptcha';


import classes from './forgotPassword.module.css';
import Hoc from './../../hoc/hoc';


const handleClick = (e) => {
	e.preventDefault();
	window.open(process.env.REACT_APP_HOME_URL,"_self")
}


const activeTranslation = 'lat';
const translations = {
	lat: {
		titles: {
			changePass: 'Promena lozinke',
			errorReset: 'Greška pri resetovanju lozinke',
			resetSuccess: 'Lozinka je uspešno promenjena!'
		},
		labels: {
			newPass: 'Unesite novu lozinku:',
			confirm: 'Potvrdite',
			linkExpired: 'Link za resetovanje lozinke je istekao!',
			linkInvalid: 'Link za resetovanje lozinke je nevažeći!',
			pass: 'Lozinka'
		},
		validation: {
			required: 'Ovo polje je obavezno',
			more6: 'Vrednost mora biti veća od 6 karaktera'
		},
		misc: {
			backHome: (<Typography component="p">
			Vratite se na <Link href="#"  onClick={(e) => {handleClick(e)}}>Početnu stranicu</Link> i ponovo zatražite email za resetovanje lozinke.
			</Typography>),
			backHomeConfirm: (<Typography component="p">
			Vratite se na <Link href="#" onClick={(e) => {handleClick(e)}}>Početnu stranicu</Link> i ulogujte se sa novom lozinkom.
		</Typography>)
		}
	},
	cyr: {
		titles: {
			changePass: 'Промена лозинке',
			errorReset: 'Грешка при ресетовању лозинке',
			resetSuccess: 'Лозинка је успешно промењена!'
		},
		labels: {
			newPass: 'Унесите нову лозинку:',
			confirm: 'Потврдите',
			linkExpired: 'Линк за ресетовање лозинке је истекао!',
			linkInvalid: 'Линк за ресетовање лозинке је неважећи!',
			pass: 'Лозинка'
		},
		validation: {
			required: 'Ово поље је обавезно',
			more6: 'Вредност мора бити већа од 6 карактера'
		},
		misc: {
			backHome: (<Typography component="p">
			Вратите се на <Link href='#' onClick={(e) => {handleClick(e)}}>Почетну страницу</Link> и поново затражите емаил за реестовање лозинке.
			</Typography>),
			backHomeConfirm: (<Typography component="p">
			Вратите се на <Link href="#" onClick={(e) => {handleClick(e)}}>Почетну страницу</Link> и улогујте се са новом лозинком.
		</Typography>)
		}
	}
}


class forgotPassword extends Component {

	state = {
		password:'',
		isVerified: false
	}

    componentDidMount () {
		this.props.onUpdatePath('/forgotPassword');
		this.props.onTryForgotPassword(this.props.match.params.hash);
	}

	handleChange = (event) => {
		const {name,value} = event.target;
		let newState = {
			...this.state,
			[name]:value
		}
		this.setState(newState);
	};

	handleSubmit = (event) => {
		this.props.onForgotPassword(this.props.match.params.hash, this.state.password);
		var newState = {
			...this.state,
			isVerified: false
		}
		this.setState(newState);
	}

	verifyCallback = (res) => {
		if (res) {
			var newState = {
				...this.state,
				isVerified: true
			}
			this.setState(newState);
		}
	}

	render () {

		let forgotBody = null;

		let disableSubmit = false;
		if (!this.state.isVerified) {
			disableSubmit = true;
		}

		//console.log(' u renderu ', this.props.verifyStatus);
		//console.log('status', this.props.forgotStatus);
		if (this.props.forgotStatus) {
			if (this.props.forgotStatus==="HASH_OK") {

				let recaptchaInstance = (
					<div className={classes.recaptchaClass}>
						<Reaptcha 
							ref={e => (this.captcha = e)}
							sitekey="6LedB7UZAAAAAIy2v0vGw_ww8BHctkVxRpMo5OWc"
							onVerify={this.verifyCallback} 
							onloadCallback={this.recaptchaCallback}
						/>
					</div>
				)

				forgotBody = (
					<Paper className={classes.paper} elevation={1}>
						<div className={classes.forgotTitle}>
							<Typography 
								align='center'
								gutterBottom 
								variant='title'>
									{translations[activeTranslation].titles.changePass}
							</Typography>
						</div>
						<Typography component="p">
							{translations[activeTranslation].labels.newPass}
						</Typography>
						<ValidatorForm
							ref="form"
							onSubmit={this.handleSubmit}
							onError={errors => console.log(errors)}
							className={classes.form}
						>
							<TextValidator
								autoComplete="new-password"
								id="password-input"
								name="password"
								label={translations[activeTranslation].labels.pass}
								className={classes.textField}
								type="password"
								margin="normal"
								onChange={this.handleChange}

								value={this.state.password}
								validators={['required','minStringLength:6']}
								errorMessages={[
									translations[activeTranslation].validation.required,
									translations[activeTranslation].more6
								]}
							/>

							{recaptchaInstance}

							<Button variant="outlined" className={classes.button} type='submit'
								disabled={disableSubmit}
							>
								{translations[activeTranslation].labels.confirm}
							</Button>
						</ValidatorForm>
					</Paper>
				);
			} else if (this.props.forgotStatus==="HASH_EXPIRED") {
				forgotBody = (
					<Hoc>
						<Typography component="h3" variant="h3" gutterBottom={true}>
							{translations[activeTranslation].titles.errorReset}
						</Typography>
						<br/>
						<Typography component="p">
							{translations[activeTranslation].labels.linkExpired}
						</Typography>
						{translations[activeTranslation].misc.backHome}
					</Hoc>
				);
			} else if (this.props.forgotStatus==="USER_NOT_FOUND") {
				forgotBody = (
					<Hoc>
						<Typography component="h3" variant="h3" gutterBottom={true}>
							{translations[activeTranslation].titles.errorReset}
						</Typography>
						<br/>
						<Typography component="p">
							{translations[activeTranslation].labels.linkInvalid}
						</Typography>
						{translations[activeTranslation].misc.backHome}
					</Hoc>
				);
			} else if (this.props.forgotStatus==="PASSWORD_UPDATED") {
				forgotBody = (
					<Hoc>
						<Typography component="h3" variant="h3" gutterBottom={true}>
							{translations[activeTranslation].titles.resetSuccess}
						</Typography>	
						<br/>
						{translations[activeTranslation].misc.backHomeConfirm}
					</Hoc>
				);
			}
		}

		return (
			<div className={classes.forgotPassword}>
                {forgotBody}
			</div>
		);
	}
};


const mapStateToProps = state => {
	return {
		curPath: state.main.currentPath,
		isAuthenticated: state.auth.token !== null,
		forgotStatus: state.auth.forgotPasswordStatus
	};
}


const mapDispatchToProps = dispatch => {
  return {
	onUpdatePath: (newpath) => dispatch (actions.changePath(newpath)),
    onTryForgotPassword: (hash) => dispatch( actions.forgotPasswordStart(hash) ),
    onForgotPassword: (hash, pass) => dispatch( actions.forgotPasswordStart(hash, pass) ),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(forgotPassword));