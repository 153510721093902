import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index'

import classes from './verifyAccount.module.css';
import Hoc from './../../hoc/hoc';

const handleClick = (e) => {
	e.preventDefault();
	window.open(process.env.REACT_APP_HOME_URL,"_self")
}


const activeTranslation = 'lat';
const translations = {
	lat: {
		titles: {
			verifyAcc: 'Verifikacija naloga',
		},
		misc: {
			backHome: (<Hoc>
				<br /><br />
				Hvala što ste verifikovali Vaš nalog!<br /><br />
				Vratite se na <Link href="#"  onClick={(e) => {handleClick(e)}}>Početnu stranicu</Link> gde možete da se ulogujte na svoj nalog.
			</Hoc>),
			invalid: (<Hoc>
				<br /><br />
				Verifikacioni link je nevažeći!<br /><br />
				Vratite se na <Link href="#"  onClick={(e) => {handleClick(e)}}>Početnu stranicu</Link>, pokušajte da se logujete <br />i zatražite novi verifikacioni email.
			</Hoc>),
			expired: (<Hoc>
				<br /><br />
				Verifikacioni link je istekao!<br /><br />
				Vratite se na <Link href="#"  onClick={(e) => {handleClick(e)}}>Početnu stranicu</Link>, pokušajte da se logujete <br />i zatražite novi verifikacioni email.
			</Hoc>
			)

		}
	},
	cyr: {
		titles: {
			verifyAcc: 'Верификација налога',
		},
		misc: {
			backHome: (<Hoc>
				<br /><br />
				Успешно сте верификовали Ваш налог!<br /><br />
				Вратите се на <Link href="#" onClick={(e) => {handleClick(e)}}>Почетну страницу</Link> где можете да се улогујете на свој налог.
			</Hoc>),
			invalid: (<Hoc>
				<br /><br />
				Верификациони линк је неважећи!<br /><br />
				Вратите се на <Link href="#" onClick={(e) => {handleClick(e)}}>Почетну страницу</Link>, покушајте да се логујете <br />и затражите нови верификациони емаил.
			</Hoc>),
			expired: (<Hoc>
				<br /><br />
				Верификациони линк је истекао!<br /><br />
				Вратите се на <Link href="#"  onClick={(e) => {handleClick(e)}}>Почетну страницу</Link>, покушајте да се логујете <br />и затражите нови верификациони емаил.
			</Hoc>
			)
		}
	}
}

class verifyAccount extends Component {

	componentDidMount() {
		this.props.onUpdatePath('/verifyAccount');
		this.props.onTryVerifyAccount(this.props.match.params.hash);
	}

	componentWillReceiveProps(newProps) {
		if (this.props.verifyStatus !== newProps.verifyStatus && newProps.verifyStatus) {

		}
	}

	render() {

		let verifyMessage = null;

		if (this.props.verifyStatus) {
			if (this.props.verifyStatus.status === "USER_NOT_FOUND") {
				verifyMessage = translations[activeTranslation].misc.expired;
			} else if (this.props.verifyStatus.status === "HASH_EXPIRED") {
				verifyMessage = verifyMessage = translations[activeTranslation].misc.invalid;
			} else if (this.props.verifyStatus.status === "EMAIL_VERIFIED") {
				verifyMessage = translations[activeTranslation].misc.backHome;
			}
		}

		return (
			<div className={classes.verifyAccount}>
				<div className={classes.container}>
					<Typography variant="h3" component="h3" gutterBottom={true}>
						{translations[activeTranslation].titles.verifyAcc}
					</Typography>
					<Typography component="p">
						{verifyMessage}
					</Typography>
				</div>
			</div>
		);
	}
};


const mapStateToProps = state => {
	return {
		curPath: state.main.currentPath,
		isAuthenticated: state.auth.token !== null,
		verifyStatus: state.auth.verifyAccountStatus
	};
}


const mapDispatchToProps = dispatch => {
	return {
		onUpdatePath: (newpath) => dispatch(actions.changePath(newpath)),
		onTryVerifyAccount: (hash) => dispatch(actions.verifyAccountStart(hash))
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(verifyAccount));