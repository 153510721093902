import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Reaptcha from 'reaptcha';

import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';

// import Hoc from '../../hoc/hoc';

import classes from './Login.module.css';

const activeTranslation = 'lat';
const translations = {
	lat: {
		messages: {
			generalError: 'Greška pri kreiranju korisničkog naloga!',
			success: 'Korisnik je kreiran',
			emailMissing: 'E-mail nije pronađen! Kreirajte novi nalog.',
			passShort: 'Lozinka mora imati više od 6 karaktera.',
			passWrong: 'Lozinka je netačna! Pokušajte ponovo.',
			phoneInvalid: 'Uneti broj telefona je neispravan.',
			jmbgInvalid: 'Uneti JMBG je neispravan.',
			emailRegistered: 'Uneseni email je već registrovan.',
			emailResetSent: 'E-mail za resetovanje lozinke je poslat!',
			emailVerifySent: 'E-mail za verifikaciju naloga je poslat!',
		},
		labels: {
			titleLogin: 'Prijavite se na nalog',
			titleRegister: 'Registrujte se',
			buttonLogin: 'Prijavite se',
			buttonForgotPass: 'Zaboravljena lozinka',
			titleRegisterSucc: 'Registracija uspešna',
			titleVerifyEmail: 'Verifikacija e-maila',
			rememberMe: 'Zapamti moje podatke.',
			nameLabel: 'Ime i prezime',
			addressLabel: 'Adresa',
			numberLabel: 'Kućni broj',
			cityLabel: 'Grad',
			zipLabel: 'Poštanski broj',
			emailLabel: 'E-mail adresa',
			phoneLabel: 'Broj telefona',
			jmbgLabel: 'JMBG',
			passLabel: 'Lozinka',
			passConfirmLabel: 'Potvrda lozinke',
			confirm: 'Potvrdite',
		},
		validation: {
			required: 'Ovo polje je obavezno',
			more6: 'Vrednost mora biti veća od 6 karaktera',
			wrongChars: 'Uneseni su nedozvoljeni karakteri',
			onlyNumbers: 'Uneseni su karakteri koji nisu brojevi',
			emailNotValid: 'E-mail nije validan',
			passMore6: 'Dužina lozinke mora biti najmanje 6 karaktera',
			minString5: 'Dužina poštanskog broja mora biti najmanje 5 brojeva',
			passNotMatch: 'Lozinke se ne podudaraju',
			wrongPhone: 'Upišite broj mobilnog telefona u formi 06XXXXXXXX',
			wrongJMBG: 'JMBG nije validan',
		},
		misc: {
			confirmation: <p className={classes.confirmationMessage}>Uspešno ste se registrovali. <br /> Na e-mail koji ste naveli pri registraciji,<br />poslali smo link za proveru.<br />
		<br /> Klikom na taj link vaš e-mail će biti potvrđen i moći ćete da se ulogujete i koristite aplikaciju.<br/><br/>Takođe, proverite Vaš spam / junk folder!<br/><br/></p>,
			emailNotValid: <p className={classes.confirmationMessage}>E-mail sa kojim ste se ulogovali nije <strong>potvrđen</strong>!<br />
				<br />Ukoliko niste dobili e-mail sa linkom za validaciju,<br /> možete ga poslati ponovo klikom na dugme "Potvrdite".<br/><br/>Takođe, proverite Vaš spam / junk folder!<br/><br/></p>
		}
	},
	cyr: {
		messages: {
			generalError: 'Грешка при креирању корисничког налога!',
			success: 'Корисник је креиран',
			emailMissing: 'Е-маил није пронађен! Креирајте нови налог.',
			passShort: 'Лозинка мора имати више од 6 карактера.',
			passWrong: 'Лозинка је нетачна! Покушајте поново.',
			emailRegistered: 'Унесени емаил је већ регистрован.',
			emailResetSent: 'Е-маил за ресетованје лозинке је послат!',
			emailVerifySent: 'Е-маил за верификацију налога је послат!',
			phoneInvalid: 'Унети број телефона је неисправан.',
			jmbgInvalid: 'Унети ЈМБГ је неисправан.',			
		},
		labels: {
			titleLogin: 'Пријавите се на налог',
			titleRegister: 'Региструјте се',
			buttonLogin: 'Пријавите се',
			buttonForgotPass: 'Заборављена лозинка',
			titleRegisterSucc: 'Регистрација успешна',
			titleVerifyEmail: 'Верификација е-маила',
			rememberMe: 'Запамти моје податке.',
			nameLabel: 'Име и презиме',
			addressLabel: 'Адреса',
			numberLabel: 'Кућни број',
			cityLabel: 'Град',
			zipLabel: 'Поштански број',
			emailLabel: 'Е-маил адреса',
			phoneLabel: 'Број телефона',
			jmbgLabel: 'ЈМБГ',
			passLabel: 'Лозинка',
			passConfirmLabel: 'Потврда лозинке',
			confirm: 'Потврдите'
		},
		validation: {
			required: 'Ово поље је обавезно',
			more6: 'Вредност мора бити већа од 6 карактера',
			wrongChars: 'Унесени су недозвољени карактери',
			onlyNumbers: 'Унесени су карактери који нису бројеви',
			emailNotValid: 'E-маил није валидан',
			passMore6: 'Дужина лозинке мора бити најмање 6 карактера',
			minString5: 'Дужина поштанског броја мора бити најмање 5 бројева',
			passNotMatch: 'Лозинке се не подударају',
			wrongPhone: 'Упишите број мобилног телефона у форми 06XXXXXXXX',
			wrongJMBG: 'ЈМБГ није валидан',
		},
		misc: {
			confirmation: <p className={classes.confirmationMessage}><br />Успешно сте се регистровали. <br /> На е-маил који сте навели при регистрацији,<br />послали смо линк за проверу.<br />
		<br /> Кликом на тај линк ваш е-маил ће бити потврђен и моћи ћете да се улогујете и користите апликацију.<br/><br/>Такође, проверите Ваш spam / junk фолдер!<br/><br/></p>,
			emailNotValid: <p className={classes.confirmationMessage}>Е маил са којим сте се улоговали није <strong>потврђен</strong>!<br />
				<br />Уколико нисте добили е-маил са линком за валидацију,<br /> можете га послати поново кликом на дугме "Потврдите".<br/><br/>Такође, проверите Ваш spam / junk фолдер!<br/><br/></p>
		}
	}
}

class Login extends Component {

	state = {
		email: '',
		password: '',
		typeOfForm: 'login',

		name: '',
		address: '',
		homenumber: '',
		city: '',
		zip: '',
		repeatPassword: '',

		tel: '',
		jmbg: '',

		remember: false,
		isVerified: true
	}

	/*
	componentWillMount() {
		//this.getSavedInfo();
	}
	*/

	componentDidMount() {
		// custom rule will have name 'isPasswordMatch'
		ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
			if (value !== this.state.password || this.state.password !== this.state.repeatPassword) {
				return false;
			}
			return true;
		});
		ValidatorForm.addValidationRule('isPasswordMatch2', (value) => {
			if (value !== this.state.repeatPassword && this.state.repeatPassword !== '') {
				return false;
			}
			return true;
		});
		// custom rule for name
		ValidatorForm.addValidationRule('isAlphaSpace', (value) => {
			var patt = /^[a-zA-Z' 'čćžđšČĆŽĐŠ]{5,}$/i;
			return patt.test(value);
		});
		// custom rule for city
		ValidatorForm.addValidationRule('isCity', (value) => {
			var patt = /^[a-zA-Z' 'čćžđšČĆŽĐŠ]{2,}$/i;
			return patt.test(value);
		});
		// custom rule for zip
		ValidatorForm.addValidationRule('isNumber', (value) => {
			var patt = /^[0-9]{5,}$/i;
			return patt.test(value);
		});
		// custom rule for zip
		ValidatorForm.addValidationRule('minString5', (value) => {
			return value.length>4
		});
		
		ValidatorForm.addValidationRule('isMobilePhone', (value) => {
			var patt = /^(06\d)\d{6,9}$/i;
			return patt.test(value);
		});
		//jmbgValidate
		ValidatorForm.addValidationRule('jmbgValidate', (jmbg) => {

			const mod11 = function (br, dodatni_uslov) {
				var kb = 0;
				for (var i = br.length - 1, mnozilac = 2; i >= 0; i--) {
					kb += parseInt(br.charAt(i), 10) * mnozilac;
					mnozilac = mnozilac === 7 ? 2 : mnozilac + 1;
				}
				kb = 11 - (kb % 11);
				return (typeof dodatni_uslov === "undefined") ? kb : dodatni_uslov(kb);
			}

			const validanDatum = function (vrednost) {
				if (Object.prototype.toString.call(vrednost) === "[object Date]")
					return !isNaN(vrednost.getTime());
				return false;
			};
			//console.log(typeof jmbg!= "undefined", jmbg!==null, jmbg.length === 13, !isNaN(parseFloat(jmbg)), isFinite(jmbg) )
			/*if (typeof jmbg !== "undefined" && jmbg !== null &&
				jmbg.length === 13 && !isNaN(parseFloat(jmbg)) && isFinite(jmbg)) {*/
			if (typeof(jmbg)==='string' && jmbg.length===0) {
				return true;
			} else if (jmbg.length === 13 && !isNaN(parseFloat(jmbg)) && isFinite(jmbg)) {
				var dan = parseInt(jmbg.substring(0, 2), 10);
				var mesec = parseInt(jmbg.substring(2, 4), 10) - 1;
				var godina = parseInt("2" + jmbg.substring(4, 7), 10);
				if (validanDatum(new Date(godina, mesec, dan))) {
					const ret = /^60|66$/.test(jmbg.substring(7, 9)) ||
						parseInt(jmbg.charAt(12), 10) === mod11(jmbg.substring(0, 12), function (kb) { return kb === 11 ? 0 : ((kb === 10) ? "X" : kb); });
					return ret;
				}
			}
			return false;
		});
	}

	getSavedInfo = () => {
		let newObj = JSON.parse(localStorage.getItem('savedAddress'));
		if (newObj && newObj.email && newObj.pass && newObj.remember) {
			var newState = {
				...this.state,
				email: newObj.email,
				password: newObj.pass,
				remember: newObj.remember
			}
			this.setState(newState);
		}
	}

	setSavedInfo = () => {
		console.log(this.state.remember);
		if (this.state.remember) {
			let data = {
				email: this.state.email,
				pass: this.state.password,
				remember: Boolean(this.state.remember)
			}
			localStorage.setItem('savedAddress', JSON.stringify(data));
		} else {
			localStorage.removeItem('savedAddress');
		}
	}

	setErrorMessage = (message) => {
		var newState = {
			...this.state,
			errorText: message
		}
		this.setState(newState);
		this.startErrorFadeOut();
	}

	startErrorFadeOut = () => {
		this.setTimeout(function () {
			this.props.onResetError();
		}, 2000);
	}

	handleChange = (event) => {
		const { name, value } = event.target;
		let newState = {
			...this.state,
			[name]: value
		}
		this.setState(newState);
	};

	handleCheckbox = (event) => {
		const { name } = event.target;
		let newState = {
			...this.state,
			[name]: event.target.checked
		}
		this.setState(newState);
	}

	handleSubmit = (event) => {
		//event.preventDefault();
		if (this.state.typeOfForm === 'login') {
			//this.setSavedInfo();
			this.props.onAuth(this.state.email, this.state.password, this.state.typeOfForm, null, null);
		} else if (this.state.typeOfForm === 'signup') {

			this.props.onAuth(this.state.email, this.state.password, this.state.typeOfForm, this.state.name, this.state.tel, this.state.jmbg, this.state.address + " " + this.state.homenumber, this.state.city, this.state.zip);
		} else if (this.state.typeOfForm === 'forgot') {

			this.props.onAuth(this.state.email, null, 'forgot', null, null);
		} else if (this.state.typeOfForm === 'emailNotVerified') {
			this.props.onSendVerificationEmail(this.state.email, this.state.password);

		}
	};

	switchAuthModeHandler = (type) => {
		let reset = true;
		if (type === 'signup' || type === 'forgot') {
			reset = false;
		}
		let newState = {
			...this.state,
			typeOfForm: type,
			isVerified: reset
		}
		this.resetCaptcha();
		this.setState(newState);
	};


	handleErrorShow = () => {
		clearTimeout(this.clearErrorTimeout);
		this.clearErrorTimeout = setTimeout(() => {
			//this.setState({isVerified:true})
			this.props.onResetError();
		}, 3000);
	};

	componentWillUnmount() {
		if (this._isMounted) {
			clearTimeout(this.clearErrorTimeout);
		}
	}

	componentWillReceiveProps = (newProps) => {
		// console.log('login - comp will receive: ', newProps.loginWarning, this.props.loginWarning, newProps.loginWarningType);
		if (newProps.loginWarning && newProps.loginWarning !== this.props.loginWarning && newProps.loginWarningType === 'user_created') {
			var newState = {
				...this.state,
				typeOfForm: 'signupDone'
			}
			this.setState(newState);
		}
		else if (newProps.loginWarning && newProps.loginWarning !== this.props.loginWarning && newProps.loginWarningType === 'email_not_verified') {
			var newState2 = {
				...this.state,
				typeOfForm: 'emailNotVerified'
			}
			this.setState(newState2);
		} else if (newProps.error && (
			newProps.error.message === 'EMAIL_RESET_SENT' ||
			newProps.error.message === 'EMAIL_RESET_SENT' ||
			newProps.error.message === 'EMAIL_NOT_FOUND'
		)
		) {
			var newState3 = {
				...this.state,
				isVerified: false
			}
			this.setState(newState3);
			this.resetCaptcha();
		}
	}

	verifyCallback = (res) => {
		//if (this.updater.isMounted()) {
		if (res) {
			var newState = {
				...this.state,
				isVerified: true
			}
			this.setState(newState);
		}
		//}
	}

	resetCaptcha = () => {
		//console.log('captcha reset');
		//this.captcha.renderExplicitly();
		this.captcha.reset();
	}

	render() {
		let errorClass = [classes.errorWindow, classes.errorWindowHide];
		let errorText = ' ';
		if (this.props.error !== null) {
			errorClass = [classes.errorWindow];
			//console.log(this.props.error.message);
			this.handleErrorShow();
			switch (this.props.error.message) {
				case 'User creation failed':
					errorText = translations[activeTranslation].messages.generalError;
					break;
				case 'USER_CREATED':
					errorText = translations[activeTranslation].messages.success;
					errorClass = [classes.errorWindow, classes.infoWindow];
					break;
				case 'EMAIL_NOT_FOUND':
					errorText = translations[activeTranslation].messages.emailMissing;
					break;
				case 'PASSWORD_SHORT':
					errorText = translations[activeTranslation].messages.passShort;
					break;
				case 'INVALID_PASSWORD':
					errorText = translations[activeTranslation].messages.passWrong;
					break;
				case 'PHONE_INVALID':
					errorText = translations[activeTranslation].messages.phoneInvalid;
					break;
				case 'EMAIL_EXISTS':
					errorText = translations[activeTranslation].messages.emailRegistered;
					break;
				case 'EMAIL_RESET_SENT':
					errorText = translations[activeTranslation].messages.emailResetSent;
					errorClass = [classes.errorWindow, classes.infoWindow];
					break;
				case 'EMAIL_VERIFY_SENT':
					errorText = translations[activeTranslation].messages.emailVerifySent;
					errorClass = [classes.errorWindow, classes.infoWindow];
					break;

				default:
					errorText = ' ';
			}
		} else {
			errorText = '&nbsp;';
		}

		let switchText = '';
		let titleText = '';

		if (this.state.typeOfForm === 'signup') {
			switchText = translations[activeTranslation].labels.titleLogin;
			titleText = translations[activeTranslation].labels.titleRegister;
		} else if (this.state.typeOfForm === 'login') {
			switchText = translations[activeTranslation].labels.titleRegister;
			titleText = translations[activeTranslation].labels.buttonLogin;
		} else if (this.state.typeOfForm === 'forgot') {
			switchText = translations[activeTranslation].labels.titleLogin;
			titleText = 'Zaboravljena lozinka';
		} else if (this.state.typeOfForm === 'signupDone') {
			switchText = translations[activeTranslation].labels.titleLogin;
			titleText = 'Registracija uspešna';
		} else if (this.state.typeOfForm === 'emailNotVerified') {
			switchText = translations[activeTranslation].labels.titleLogin;
			titleText = 'Verifikacija e-mail adrese';
		}

		let disableSubmit = false;

		if (!this.state.isVerified) {
			disableSubmit = true;
		}

		let reapthcaClasses = [classes.recaptchaClass, classes.hide];
		let recaptchaBreak = <div style={{display:'block',margin:'10px auto'}}></div>

		if (this.state.typeOfForm === 'signup' || this.state.typeOfForm === 'forgot') {
			reapthcaClasses = [classes.recaptchaClass]
			recaptchaBreak = null;
		}


		let recaptchaInstance = (
			<div className={reapthcaClasses.join(' ')}>
				<Reaptcha
					ref={e => (this.captcha = e)}
					sitekey="6LedB7UZAAAAAIy2v0vGw_ww8BHctkVxRpMo5OWc"
					onVerify={this.verifyCallback}
					hl='sr'
				/>
			</div>
		)

		return (
			<div className={classes.loginWindow}>
				<div className={classes.loginTitle}>
					<Typography
						align='center'
						gutterBottom
						variant='subtitle1'>
						{titleText}
					</Typography>
				</div>
				<ValidatorForm
					ref="form"
					onSubmit={this.handleSubmit}
					onError={errors => console.log(errors)}
					className={classes.form}

				>
					{
						(this.state.typeOfForm === 'signup')
							? (
								<TextValidator
									className={classes.textField}
									autoComplete="off"
									label={translations[activeTranslation].labels.nameLabel}
									name="name"
									value={this.state.name}
									onChange={this.handleChange}
									validators={['required', 'minStringLength:6', 'isAlphaSpace']}
									errorMessages={[
										translations[activeTranslation].validation.required,
										translations[activeTranslation].validation.more6,
										translations[activeTranslation].validation.wrongChars
									]}
								/>
							)
							: null
					}
					{
						(this.state.typeOfForm !== 'signupDone' && this.state.typeOfForm !== 'emailNotVerified')
							? (
								<TextValidator
									className={classes.textField}
									autoFocus
									margin="normal"
									autoComplete="off"
									label={translations[activeTranslation].labels.emailLabel}
									name="email"
									value={this.state.email}
									onChange={this.handleChange}
									validators={['required', 'isEmail']}
									errorMessages={[
										translations[activeTranslation].validation.required,
										translations[activeTranslation].validation.emailNotValid
									]}
								/>
							)
							: null
					}

					<div className={classes.fieldColumns}>
					{
						(this.state.typeOfForm === 'signup')
							? (
								<TextValidator
									className={classes.textField+ ' ' + classes.larger}
									autoComplete="off"
									label={translations[activeTranslation].labels.addressLabel}
									name="address"
									value={this.state.address}
									onChange={this.handleChange}
									validators={['required']}
									errorMessages={[
										translations[activeTranslation].validation.required
									]}
								/>
							)
							: null
					}

					{
						(this.state.typeOfForm === 'signup')
							? (
								<TextValidator
									className={classes.textField + ' ' + classes.ml + ' ' + classes.smaller}
									autoComplete="off"
									label={translations[activeTranslation].labels.numberLabel}
									name="homenumber"
									value={this.state.homenumber}
									onChange={this.handleChange}
									validators={['required']}
									errorMessages={[
										translations[activeTranslation].validation.required
									]}
								/>
							)
							: null
					}
					</div>

					<div className={classes.fieldColumns}>
					{
						(this.state.typeOfForm === 'signup')
							? (
								<TextValidator
									className={classes.textField}
									autoComplete="off"
									label={translations[activeTranslation].labels.cityLabel}
									name="city"
									value={this.state.city}
									onChange={this.handleChange}
									validators={['required', 'isCity']}
									errorMessages={[
										translations[activeTranslation].validation.required,
										translations[activeTranslation].validation.wrongChars
									]}
								/>
							)
							: null
					}
					{
						(this.state.typeOfForm === 'signup')
							? (
								<TextValidator
									className={classes.textField + ' ' + classes.ml}
									autoComplete="off"
									label={translations[activeTranslation].labels.zipLabel}
									name="zip"
									value={this.state.zip}
									onChange={this.handleChange}
									validators={['required', 'minString5', 'isNumber']}
									errorMessages={[
										translations[activeTranslation].validation.required,
										translations[activeTranslation].validation.minString5,
										translations[activeTranslation].validation.onlyNumbers,
									]}
								/>
							)
							: null
					}

					</div>
					<div className={classes.fieldColumns}>
					{
						(this.state.typeOfForm === 'login' || this.state.typeOfForm === 'signup')
							? (
								<TextValidator
									autoComplete="new-password"
									id="password-input"
									name="password"
									label={translations[activeTranslation].labels.passLabel}
									className={classes.textField}
									type="password"
									margin="normal"
									onChange={this.handleChange}

									value={this.state.password}
									validators={['required', 'minStringLength:6', 'isPasswordMatch2']}
									errorMessages={[
										translations[activeTranslation].validation.required,
										translations[activeTranslation].validation.passMore6,
										translations[activeTranslation].validation.passNotMatch
									]}
								/>
							)
							: null
					}

					{
						(this.state.typeOfForm === 'signup')
							? (
								<TextValidator
									id="password-input2"
									name="repeatPassword"
									label={translations[activeTranslation].labels.passConfirmLabel}
									className={classes.textField + ' ' + classes.ml}
									type="password"
									margin="normal"
									autoComplete="off"
									onChange={this.handleChange}

									value={this.state.repeatPassword}
									validators={['required', 'minStringLength:6', 'isPasswordMatch']}
									errorMessages={[
										translations[activeTranslation].validation.required,
										translations[activeTranslation].validation.passMore6,
										translations[activeTranslation].validation.passNotMatch
									]}
								/>
							)
							: null
					}
					</div>

					{ /* telefon i jmbg */}
					<div className={classes.fieldColumns}>
					{
						(this.state.typeOfForm === 'signup')
							? (
								<TextValidator
									className={classes.textField}
									margin="normal"
									autoComplete="off"
									label={translations[activeTranslation].labels.phoneLabel}
									name="tel"
									value={this.state.tel}
									onChange={this.handleChange}
									validators={['required', 'isMobilePhone']}
									errorMessages={[
										translations[activeTranslation].validation.required,
										translations[activeTranslation].validation.wrongPhone
									]}
								/>
							)
							: null
					}

					{
						(this.state.typeOfForm === 'signup')
							? (
								<TextValidator
									id="jmbg-input"
									name="jmbg"
									label={translations[activeTranslation].labels.jmbgLabel}
									className={classes.textField + ' ' + classes.ml}
									autoComplete="off"
									margin="normal"
									onChange={this.handleChange}

									value={this.state.jmbg}
									validators={['required', 'jmbgValidate']}
									errorMessages={[
										translations[activeTranslation].validation.required,
										translations[activeTranslation].validation.wrongJMBG
									]}
								/>
							)
							: null
					}
					</div>

					{
						(this.state.typeOfForm === 'signupDone')
							? (translations[activeTranslation].misc.confirmation)
							: null
					}

					{
						(this.state.typeOfForm === 'emailNotVerified')
							? (translations[activeTranslation].misc.emailNotValid)
							: null
					}

					{recaptchaInstance}
					{recaptchaBreak}

					{errorText.length>7
					?(	<div className={errorClass.join(' ')}>
							{errorText}
						</div>
					)
					: null
					}

					{
						(this.state.typeOfForm !== 'signupDone')
							? (<Button variant="outlined" className={classes.button} type='submit'
								disabled={disableSubmit}
							>
								{translations[activeTranslation].labels.confirm}
							</Button>
							)
							: null
					}
				</ValidatorForm>



				<div className={classes.buttonSwitchHolder}>
					<Button
						color="primary"
						className={classes.buttonSwitch}
						onClick={() => {
							if (this.state.typeOfForm === 'login') {
								this.switchAuthModeHandler('signup');
							} else if (this.state.typeOfForm === 'signup') {
								this.switchAuthModeHandler('login');
							} else if (this.state.typeOfForm === 'signupDone' || this.state.typeOfForm === 'emailNotVerified') {
								this.switchAuthModeHandler('login');
								this.props.onSetLoginWarning(false, null);
							} else {
								this.switchAuthModeHandler('login');
							}
						}}
					>
						{switchText}
					</Button>
					{
						(this.state.typeOfForm === 'login')
							? (
								<Button
									color="secondary"
									className={classes.buttonSwitch}
									onClick={() => {
										this.switchAuthModeHandler('forgot');
									}}
								>
									{translations[activeTranslation].labels.buttonForgotPass}
								</Button>
							)
							: null
					}
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		loading: state.auth.loading,
		error: state.auth.error,
		isAuthenticated: state.auth.token !== null,
		loginWarning: state.auth.loginWarning,
		loginWarningType: state.auth.loginWarningType,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onSendVerificationEmail: (email, password) => dispatch(actions.loginAndSendVerificationEmail(email, password, false)),
		onAuth: (email, password, isLogin, name, phone, jmbg, address, city, zip) => dispatch(actions.auth(email, password, isLogin, name, phone, jmbg, address, city, zip)),
		onResetError: () => dispatch(actions.resetErrorState()),
		onSetLoginWarning: (war, wartype) => dispatch(actions.loginWarning(war, wartype)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);