import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	landingPageReady: false,
	showModal: false,
	showInModal:'',
	landingPagePosition: 0,
	landingPageElement:'',
	showSideDrawer: false,
	spinnerState:true,
	currentPath:'/',
};

const setLandingPageReady = (state, action) => {
    return updateObject (state, {
        landingPageReady: true
    })
}

const setModal = (state, action) => {
    return updateObject (state, {
		showModal:action.modalState,
		showInModal:action.showInModal
    })
}

const setLandingPagePosition = (state, action) => {
    return updateObject (state, {
		landingPagePosition:action.LPPosition,
    })
}

const setLandingPageElement = (state, action) => {
    return updateObject (state, {
		landingPageElement:action.LPElement
    })
}

const setSideDrawer = (state, action) => {
    return updateObject (state, {
		showSideDrawer:action.sideDrawerChange
    })
}

const setSpinner = (state, action) => {
    return updateObject (state, {
		spinnerState:action.spinner
    })
}

const setPath = (state, action) => {
    return updateObject (state, {
		currentPath:action.path
    })
}


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
		case actionTypes.LANDINGPAGE_READY: return setLandingPageReady(state, action);
		case actionTypes.CHANGE_MODAL: return setModal(state, action);
		case actionTypes.CHANGE_LANDINGPAGEPOSITION: return setLandingPagePosition(state, action);
		case actionTypes.CHANGE_LANDINGPAGEELEMENT: return setLandingPageElement(state, action);
		case actionTypes.CHANGE_SIDEDRAWER: return setSideDrawer(state, action);
		case actionTypes.SPINNER_STATE: return setSpinner(state, action);
		case actionTypes.CHANGE_PATH: return setPath(state, action);
        default:
            return state;
    }
};

export default reducer;