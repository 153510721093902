import React, {Component} from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import classes from './LoadingSpinner.module.css'

class LoadingSpinner extends Component {
  render () {
    return (
      <div className={classes.container}>
        <div className={classes.progress}>
          <CircularProgress className={classes.icon} mode="indeterminate" size={80} />
        </div>
      </div>
    );
  }
};

export default LoadingSpinner;