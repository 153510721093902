import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import AddPhoto from "@material-ui/icons/AddAPhoto";
// import moment from 'moment-timezone';

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import LogoutIcon from "@material-ui/icons/PowerSettingsNew";
import Save from "@material-ui/icons/Save";

import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import Avatar from "react-avatar-edit";

import ReactGA from "react-ga";

import { withRouter } from "react-router-dom";

import Spinner from "../../components/UI/LoadingSpinner";
import Hoc from "../../hoc/hoc";

import classes from "./Profile.module.css";

class Profile extends Component {
  /*
		constructor(props) {
			super(props)
			this.state = {
				preview: null
			}
			this.onCrop = this.onCrop.bind(this)
			this.onClose = this.onClose.bind(this)
		}
	*/

  state = {
    preview: null,
    authToken: null,
    profileArr: null,
  };

  /*
	shouldComponentUpdate(nextProps,nextState) {
		if (nextProps.authToken!==null) {
			return true;
		}
		console.log(nextProps,nextState);
		return false;
	}
	*/

  componentWillReceiveProps(nextProps) {
    //console.log('willreceive',nextProps.isAuthenticated);
    if (
      nextProps.authToken !== this.state.authToken &&
      nextProps.isAuthenticated
    ) {
      this.setState({
        ...this.state,
        authToken: nextProps.authToken,
      });
      this.props.onGetProfile(nextProps.authToken, nextProps.userID);
    } else if (!nextProps.isAuthenticated) {
      this.props.history.push("/");
      this.props.onUpdatePath("/");
    }
  }

  componentDidMount() {
    //console.log('didmount', this.props.isAuthenticated, this.props.curPath);
    window.scrollTo(0, 0);
    this.props.onGetProfile(this.props.authToken, this.props.userID);
    this.props.onUpdatePath("/profil");
    //this.props.onUpdateActiveMenu(null);
    //this.props.onGetResults(this.props.userID);
  }

  onClose = () => {
    this.setState({
      ...this.state,
      preview: null,
    });
  };

  onCrop = (preview) => {
    this.setState({
      ...this.state,
      preview: preview,
    });
  };

  activateDeleteConfirmation = () => {
    ReactGA.event({
      category: "Profile Action",
      action: "Delete initialization",
    });

    this.props.onUpdateModal(true, "deleteConfirm");
  };

  saveAvatarIcon = () => {
    this.props.onUploadAvatar(
      this.state.preview,
      this.props.userID,
      this.props.authToken,
      this.props.profileArray
    );
  };

  deleteAvatarIcon = () => {
    this.props.onDeleteAvatar(
      this.props.userID,
      this.props.authToken,
      this.props.profileArray
    );
  };

  activateLogout = () => {
    this.props.onLogout(this.props.authToken, this.props.userID);
    this.props.onUpdatePath("/");
  };

  render() {
    
    let spin = null;
		if (this.props.spin) {
			spin = <Spinner />
		}

		let results, points, position, enemypoints, week;

		if (this.props.profileArray) {
			if (this.props.profileArray.message) {
				week = <div><h3>Rezultati</h3></div>
				if (this.props.profileArray.message === "points_ok") { 
					if (this.props.profileArray.myplace === 0) {
						position = <div style={{ fontSize: '1.5rem' }}><h5>Prvo mesto na rang listi!</h5></div>
					} else if (this.props.profileArray.myplace > 0) {
						position = <div><h5>Moja pozicija na rang listi:</h5> <span>{this.props.profileArray['myplace'] + 1}</span></div>
						enemypoints = <div><h5>Broj poena korisnika na <span>{this.props.profileArray['myplace']}.</span> mestu:</h5> <span>{this.props.profileArray['enemypoints']}</span></div>
					}
					points = <div><h4>Moj broj poena:</h4> <span>{this.props.profileArray['mypoints']}</span></div>
				} else {
					points = <div><h4>Moj broj poena:</h4> <span>0</span></div>
				}
			}

		}

		results = <Hoc>{week}{points}{position}{enemypoints}</Hoc>;

    return (
      <Hoc>
        <div className={classes.container}>
          <section id="container">
            <div
              className={classes.profileWindow}
              location={this.props.curPath}
              ref={(div) => {
                this.zero = div;
              }}
            >
              <div className={classes.profileTitle}>
                <Typography align="center" variant="h5">
                  Moj profil
                </Typography>
              </div>
              <hr />
              <div className={classes.profileData}>
                <Paper elevation={1} className={classes.avatar}>
                  <div className={classes.avatarHolder}>
                    {this.props.avatarUrl ? (
                      <Hoc>
                        <img
                          src={"data:image/png;base64," + this.props.avatarUrl}
                          alt="Avatar"
                        />
                        <IconButton
                          color="primary"
                          aria-label="Obriši profilnu sliku"
                          title="Obriši profilnu sliku"
                          onClick={this.deleteAvatarIcon}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Hoc>
                    ) : (
                      <Hoc>
                        <div
                          style={{
                            display: "block",
                            height: "4vh",
                            width: "1px",
                          }}
                        ></div>
                        <AddPhoto className={classes.photoIcon} />
                        <Avatar
                          width={160}
                          height={160}
                          label="Izaberite sliku"
                          closeIconColor="#333"
                          onCrop={this.onCrop}
                          onClose={this.onClose}
                          src={this.state.src}
                          class={classes.avatarTool}
                        />
                        {/*<img src={this.state.preview} alt="Preview"/>*/}
                        <IconButton
                          color="primary"
                          aria-label="Snimi profilnu sliku"
                          disabled={this.state.preview ? false : true}
                          onClick={this.saveAvatarIcon}
                        >
                          <Save />
                        </IconButton>
                      </Hoc>
                    )}
                  </div>
                </Paper>

                <div className={classes.info}>
                  <div className={classes.infoFields}>
                    <Typography
                      align="left"
                      variant="body2"
                      className={classes.profileInputLabel}
                    >
                      Ime i prezime:
                    </Typography>
                    <Typography
                      align="left"
                      gutterBottom
                      variant="body1"
                      className={classes.profileInput}
                    >
                      {this.props.profileArray["name"]}
                    </Typography>

                    <Typography
                      align="left"
                      variant="body2"
                      className={classes.profileInputLabel}
                    >
                      E-mail:
                    </Typography>
                    <Typography
                      align="left"
                      gutterBottom
                      variant="body1"
                      className={classes.profileInput}
                    >
                      {this.props.profileArray["email"]}
                    </Typography>

                    <Typography
                      align="left"
                      variant="body2"
                      className={classes.profileInputLabel}
                    >
                      Adresa:
                    </Typography>
                    <Typography
                      align="left"
                      gutterBottom
                      variant="body1"
                      className={classes.profileInput}
                    >
                      {this.props.profileArray["address"]}
                    </Typography>

                    <Typography
                      align="left"
                      variant="body2"
                      className={classes.profileInputLabel}
                    >
                      Grad:
                    </Typography>
                    <Typography
                      align="left"
                      gutterBottom
                      variant="body1"
                      className={classes.profileInput}
                    >
                      {this.props.profileArray["city"]}
                    </Typography>

                    <Typography
                      align="left"
                      variant="body2"
                      className={classes.profileInputLabel}
                    >
                      Poštanski broj:
                    </Typography>
                    <Typography
                      align="left"
                      gutterBottom
                      variant="body1"
                      className={classes.profileInput}
                    >
                      {this.props.profileArray["zip"]}
                    </Typography>

                    <Typography
                      align="left"
                      variant="body2"
                      className={classes.profileInputLabel}
                    >
                      Telefon:
                    </Typography>
                    <Typography
                      align="left"
                      gutterBottom
                      variant="body1"
                      className={classes.profileInput}
                    >
                      {this.props.profileArray["phone"]}
                    </Typography>
                    {this.props.profileArray ? (
                      this.props.profileArray["jmbg"] ? (
                        <React.Fragment>
                          <Typography
                            align="left"
                            variant="body2"
                            className={classes.profileInputLabel}
                          >
                            JMBG:
                          </Typography>
                          <Typography
                            align="left"
                            gutterBottom
                            variant="body1"
                            className={classes.profileInput}
                          >
                            {this.props.profileArray["jmbg"]}
                          </Typography>
                        </React.Fragment>
                      ) : null
                    ) : null}
                  </div>
                  <div className={classes.profileButtonsHolder}>
                    <div className={classes.deleteButtonHolder}>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={this.activateDeleteConfirmation}
                      >
                        <span className={classes.buttonLabel}>
                          Obriši nalog
                        </span>
                        <DeleteIcon className={classes.rightIcon} />
                      </Button>
                    </div>
                    <div className={classes.logoutButtonHolder}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={this.activateLogout}
                      >
                        <span className={classes.buttonLabel}>Odjava</span>
                        <LogoutIcon className={classes.rightIcon} />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.bodoviHolder}>{/*results*/}</div>
            <div className={classes.SpinnerHolder}>{spin}</div>
          </section>
        </div>
      </Hoc>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token,
    userID: state.auth.userId,
    profileArray: state.auth.profile,
    previousResults: state.auth.previousResults,
    isAuthenticated: state.auth.token !== null,
    curPath: state.main.currentPath,
    spin: state.auth.loading,
    avatarUrl: state.auth.avatarData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetProfile: (tok, id) => dispatch(actions.getProfile(tok, id)),
    //onUpdateActiveMenu: (active) => dispatch (actions.changeActiveMenu(active)),
    onUpdateModal: (modState, modWhat) => dispatch(actions.changeModal(modState, modWhat)),
    onUpdatePath: (newpath) => dispatch(actions.changePath(newpath)),
    onLogout: (tok, id) => dispatch(actions.startLogout(tok, id)),
    onUploadAvatar: (imgData, uid, tok, parr) => dispatch(actions.setAvatar(imgData, uid, tok, parr)),
    onDeleteAvatar: (uid, tok, parr) => dispatch(actions.removeAvatar(uid, tok, parr)),
  };
};

//export default connect(mapStateToProps,mapDispatchToProps)(Profile);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
