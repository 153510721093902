import React, { Component } from 'react';
import classes from './Pravila.module.css';

import Spinner from '../../components/UI/LoadingSpinner'
import Grid from '@material-ui/core/Grid';

import Hoc from '../../hoc/hoc';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

class Pravila extends Component {

	componentWillMount() {
		this.props.spinnerChange(true);
		if (this.props.curPath !== '/kontakt-i-pravila') {
			this.props.onUpdatePath('/kontakt-i-pravila');
		}
	}

	componentDidMount() {
		this.props.onRdyLanding();
		this.props.spinnerChange(false);
	};

	render() {

		let spin = null;
		if (this.props.spin) {
			spin = <Spinner />
		}

		return (
			<Hoc>
				<div className={classes.container} location={this.props.curPath}>
					<section id="container">
						<Grid container spacing={0} className={classes.grid}>
							<Grid item xs></Grid>
							<Grid item xs={11} md={8}>
								<h2>Kontakt</h2>
								<div className={classes.kontaktHolder}>
									<p>Za sva pitanja i nedoumice pišite nam na</p>
									<a className={classes.emailLink} href="mailto:birramoretti.konkurs@gmail.com">birramoretti.konkurs@gmail.com</a>
									<p>ili pozovite <small><small>(radnim danom od 09:00 do 17:00h)</small></small></p>
									<a className={classes.emailLink} href="tel:0800100155">0800 / 100 155</a>
								</div>
								<br/><br/>							
								<hr/>
								<br/><br/>


								<div className={classes.pravilaHolder}>
								<h2>Pravila</h2>
								<p>U skladu sa članom 232. Zakona o obligacionim odnosima (Službeni list SFRJ, br. 29/78, 39/85, 45/89, Službeni list SRJ, br. 31/93, Službeni list SCG, br. 1/2003 i Službeni glasnik RS, br. 18/2020), zastupnici društva Heineken Srbija doo Zaječar, sa registrovanim sedištem u Zaječaru, na adresi Železnička broj 2 (u daljem tekstu "<strong>Organizator</strong>"), usvajaju dana 05.09.2022. sledeća:</p>
								<h3>
									PRAVILA<br />
  									NAGRADNOG KONKURSA<br />
  									"BIRRA MORETTI TE NAGRAĐUJE"
								</h3>
								<ol>
									<li>
										<h4>Opšte odredbe</h4>
										<ol>
											<li> Radi organizovanja nagradnog konkursa pod nazivom "<strong>BIRRA MORETTI TE NAGRAĐUJE</strong>" (u daljem tekstu "<strong>Konkurs</strong>") zastupnici Organizatora donose ova pravila kao što sledi (u daljem tekstu "<strong>Pravila</strong>").</li>
											<li> Konkurs se organizuje na osnovu Javnog obećanja nagrade, u skladu sa članom 232 Zakona o obligacionim odnosima ("Sl. list SFRJ", br. 29/78, 39/85, 45/89 - odluka USJ i 57/89, "Sl. list SRJ", br. 31/93, "Sl. list SCG", br. 1/2003-Ustavna povelja i "Sl. glasnik RS", br.18/2020).</li>
											<li>Naziv Konkursa je "<strong>BIRRA MORETTI TE NAGRAĐUJE</strong>".</li>
											<li>Konkurs počinje dana 13.11.2023. godine u 00:00:01 časova i završava se dana 17.12.2023. godine u 23:59:59 časova.</li>
											<li>Po isteku Konkursa se na osnovu rang liste učesnika dodeljuju sve definisane nagrade.</li>
											<li>Proglašenje dobitnika nagrada Konkursa će biti izvršeno na način i u rokovima propisanim Pravilima, a na osnovu rang liste učesnika koja se formira na osnovu broja bodova učesnika (u daljem tekstu: "Rang lista").</li>
											<li>Konkurs se organizuje u svrhu promocije proizvoda Organizatora i to piva robne marke Birra Moretti.</li>
											<li>Konkurs se organizuje na teritoriji Republike Srbije (u daljem tekstu: "Teritorija").</li>
											<li>Konkurs se organizuje putem web stranice <a href="https://www.birramoretti.com/rs/sr/birramorettinagradjuje" target="_blank" rel="noopener noreferrer">www.birramoretti.com/rs/sr/birramorettinagradjuje</a> a na način i pod uslovima bliže opisanim u ovim Pravilima. </li>
											<li>Predmet Konkursa je izbor dobitnika, koji su sakupili najviše bodova u okviru kviza, koji je predmet ovih Pravila i u skladu sa ovim Pravilima. </li>
										</ol>
									</li>
									<li>
										<h4>Pravo učestvovanja na Konkursu</h4>
										<ol>
											<li>Pravo učešća na Konkursu imaju sledeća lica:
												<ol>
													<li>
														lica koja su navršila 18 godina života,
													</li>
													<li>
														državljani Republike Srbije,
            										</li>
													<li>
														sa prebivalištem na teritoriji Republike Srbije (u daljem tekstu: <strong>Učesnik</strong>)
            										</li>
												</ol>
											</li>
											<li>Pravo učešća na Konkursu nemaju sledeća lica:
	      										<ol>
													<li>
														Lica koja su u stalnom radnom odnosu ili su po drugom pravnom osnovu angažovana na radu kod Organizatora, kao ni njihovi supružnici ili vanbračni partneri, roditelji i deca;
													</li>
													<li>
														lica koja su u stalnom radnom odnosu ili su po drugom osnovu angažovana kod pravnih i/ili fizičkih lica koja na bilo koji način učestvuju u organizovanju Konkursa i/ili imaju pristup podacima vezanim za priređivanje Konkursa, kao ni njihovi supružnici ili vanbračni partneri, roditelji i deca.
		   											</li>
												</ol>
											</li>
										</ol>
									</li>
									<li><h4>Mehanizam Konkursa</h4>
										<ol>
											<li>
											Da bi lice steklo pravo da učestvuje na Konkursu potrebno je da u Idea, Roda i Mercator prodajnim objektima kupi 3 piva robne marke Birra Moretti u pakovanjima 0,5l limenka, 0,4l povratna boca, 0,33l nepovratna boca, 0,33l limenka i da sačuva fiskalni račun od kupovine na kojem je vidljivo da je izvršena kupovina piva Birra Moretti, kako je navedeno u ovom stavu, kao i PFR broj računa (u daljem tekstu: <strong>"Kod"</strong>)

											</li><li>
											Nakon kupovine Birra Moretti piva potrebno je da Učesnik preduzme sledeće korake:
												<ol>
													<li>
													Poseti web stranicu: <a href="https://www.birramoretti.com/rs/sr/birramorettinagradjuje" target="_blank" rel="noopener noreferrer">www.birramoretti.com/rs/sr/birramorettinagradjuje</a> (u daljem tekstu: <strong>Web stranica</strong>).
													</li><li>
														Registruje se na Web stranici tako što je potrebno da popuni sledeće podatke:
														<ul>
															<li>
																Ime i prezime
															</li>
															<li>
																e-mail adresu
															</li>															
															<li>
																Adresa stanovanja, mesto i poštanski broj
															</li>
															<li>
																JMBG
															</li>
															<li>
																Broj mobilnog telefona
															</li>
															<li>
																Šifru naloga
															</li>
														</ul>
													</li><li>
														Klikom na dugme registruj se, Učesnik prihvata Pravila koja se nalaze na Web stranici na kartici Pravila i postaje Učesnik. Svi uneti podaci moraju biti istiniti i tačni. Registracije u drugačijoj formi od one koja je navedena u ovim Pravilima se neće smatrati ispravnim i neće učestvovati u Konkursu. Učesniku se nakon uspešno unetih neophodnih podataka za registraciju šalje e-mail poruka na adresu koju je uneo u formi za registraciju. Neophodno je da Učesnik verifikuje svoju e-mail adresu, odnosno aktivira svoj Profil, klikom na link iz e-mail poruke. Tek nakon aktivacije Profila, Učesnik može pristupiti svojoj Profil stranici. Podaci uneti pri prvoj registraciji se sistematski pamte, pa nije potrebno da isti Učesnik pri drugom i svakom narednom pristupu Web stranici ponovo unosi ranije navedene podatke, osim e-mail adrese i šifre. Ukoliko je Učesnik zaboravio šifru, pritiskom na dugme "Zaboravio/la si šifru?" na e-mail adresu datu pri registraciji šalje se link za resetovanje šifre, nakon čega učesnik može opet da se prijavi na web stranici.
													</li><li>
														Nakon ispunjenja uslova iz odredbe člana 3.2.1 - 3.2.3. Pravila, potrebno je da Učesnik unese PFR broj računa, pošalje sliku i odigra Kviz kako bi mogao da osvoji poene za rangiranje na Rang listi.
													</li><li>
														Na stranici Profil se nalazi broj osvojenih bodova (ukupan broj bodova osvojen u svim odigranim partijama Kviza), pozicija na Rang listi (u daljem tekstu: "Profil"). U glavnom meniju se nalazi dugme "Birra Moretti nagrađuje" i klikom na to dugme Učesniku se otvara stranica za unošenje Koda. Kod je potrebno uneti u naznačena polja. Nakon unetog Koda, Učesnik je dužan da doda i sliku fiskalnog računa. Svakim uspešno unetim Kodom na gore opisan način Učesnik dobija mogućnost da odgovara na pitanje iz Kviza.
													</li><li>
														Učesnik odgovara na 1 pitanje za koje ima 10 sekundi na raspolaganju tri ponuđena odgovora od kojih je samo jedan tačan odgovor (u daljem tekstu „Kviz“). Svaki tačan odgovor vredi jedan poen. U slučaju da Učesnik ne odgovori u roku od 10 sekundi, smatraće se kao da je netačno odgovorio. Učesniku će se po isteku desete sekunde automatski završiti partija Kviza. Učesnik bira odgovor klikom na jedan od tri ponuđena odgovora. Učesniku je preostalo vreme koje ima na raspolaganju za izbor odgovora prikazano u obliku sata koji odbrojava unazad od 10 do 0. Po završetku Kviza, Učesnik dobija informaciju da li je tačno odgovorio na pitanje a poen će biti dodat na njegov profil tek nakon validacije, a kojim se na Rang listi rangira za nagradu.
													</li><li>
														Učesnik može, za vreme trajanja Konkursa, da unese neograničen broj ispravnih Kodova i tako steći uslov za učestvovanje u Kvizu. Unosi koji nisu potvrđeni kroz sistem, koji su netačni ili pak nisu uneti na način objašnjen u članu 3.2.6. neće biti važeći i neće omogućiti Učesniku da učestvuje u Konkursu. Jednom poslat Kod ne može se koristiti više puta.
													</li><li>
														Svaki Kod može da bude iskorišćen samo jednom. Ukoliko Učesnik pokuša da ponovo iskoristiti već uneti Kod, na ekranu će izaći poruka da je kod neispravan.
													</li><li>
														Nasumično unošenje kodova predstavlja osnov za privremenu ili trajnu diskvalifikaciju iz takmičenja.
													</li>
												</ol>
											</li>
											<li>Nakon svake ponovne kupovine potrebno je da Učesnik pristupi Web stranici i preduzme korake navedene u članovima 3.2.4 Pravila</li>
											<li>Učesnik u svakom trenutku može proveriti broj bodova koje ima na Profilu, kao i na kojoj se poziciji na Rang listi nalazi. Bodovi će biti prikazani tek nakon verifikacije računa. </li>
											<li>Učesnik je dužan da sačuva svaki račun sa kog je uneo Kod, i to tako da je ceo fiskalni račun očuvan do reči „KRAJ FISKALNOG RAČUNA“ i da iste preda Organizatoru na njegov zahtev bez obzira na to za koju se nagradu takmiči. </li>
											<li>Organizator ne snosi odgovornost u slučaju bilo kakvih tehničkih nedostataka prilikom registracije ili igranja Kviza bez obzira na uzrok tehničkog nedostatka, ali će u svakom slučaju angažovati stručno lice u cilju pružanja tehničkih usluga vezano za organizaciju i sprovođenje Konkursa.</li>
											<li>Korišćenje bilo kakve vrste kompjuterskog koda, virusa, ili bilo čega što može ometati, onemogućiti, naneti štetu, ili zloupotrebiti učestvovanje u Konkursu je zabranjeno. Takvi postupci predstavljaju osnov za eliminaciju Učesnika. </li>
											<li>Komisija koju formira Organizator se sve vreme trajanja Konkursa stara o ispunjenosti svih uslova, ispravnosti i formiranju Rang liste, ispunjenosti uslova od strane Učesnika i nadgleda ispravnost sprovođenja samog Konkursa.</li>
										</ol>    
									</li>
									<li><h4>Izbor i odlučivanje o dobitnicima Nagrada </h4>
										<ol>
											<li>
												Po isteku Konkursa dobitnici nagrade se određuju na osnovu bodova koje Učesnik skupi učestvujući u Kvizu. U slučaju da dva ili više Učesnika imaju isti broj bodova, prednost u rangiranju ima Učesnik koji je prvi ostvario taj broj bodova. 
											</li>
											<li>
												Spisak Učesnika, koji su proglašeni kao dobitnici, će biti objavljen putem Web stranice na stranici Lista dobitnika u roku od 24 časa od trenutka završetka Konkursa kao i na drugi način za koji Organizator smatra da je potrebno uključujući i sve medije (štampani, elektronski, itd).
											</li>
											<li>
												Isto fizičko lice može osvojiti samo jednu nagradu, bez obzira na to sa koliko različitih profila je poslao odgovor.<br/><br/>

											Ukupno postoji <strong>925 nagrada</strong> koje Organizator dodeljuje. Sve nagrade se dodeljuju nakon 42 (četrdeset i dva) dana, koliko traje <strong>Konkurs</strong>, na osnovu Rang liste. 
 
												<ol>
													<li>
														<strong>Glavna nagrada  </strong>
														<br/>
														<br/>
														Učesnici rangirani od 1. zaključno sa 5. mestom na Rang listi osvajaju vaučer u iznosu od 100.000 RSD za kupovinu u Matis maloprodajnim objektima. Vaučer važi od 01.01. do 31.03.2024. godine.
													</li>
													<li>
														<strong>Ostale nagrade </strong>
														<br/>
														<br/>
														<ul>
															<li>
															Učesnici rangirani od 6. zaključno sa 15. mestom na Rang listi osvajaju vaučer u iznosu od 50.000 RSD za kupovinu u Matis maloprodajnim objektima. Vaučer važi od 01.01. do 31.03.2024. godine.
															</li>
															<li>
															Učesnici rangirani od 16. zaključno sa 25. mestom na Rang listi osvajaju vaučer u iznosu od 12.000 RSD za kupovinu u Mercator maloprodajnim objektima (Mercator, Roda i Idea). Vaučer važi od 01.01. do 31.03.2024. godine.
															</li>
															<li>
															Učesnici rangirani od 26. zaključno sa 125. mestom na Rang listi osvajaju Birra Moretti mlin za biber.
															</li>
															<li>
															Učesnici rangirani od 126. zaključno sa 425. mestom na Rang listi osvajaju Birra Moretti tanjir za pastu.
															</li>
															<li>
															Učesnici rangirani od 426. zaključno sa 925. mestom na Rang listi osvajaju Birra Moretti podmetač za hranu.
															</li>
														</ul>
													</li>
												</ol>
												Rang lista dobitnika se objavljuje odmah, nakon završetka Konkursa.
											</li>
										</ol>
									</li>
									<li><h4>Preuzimanje Nagrada</h4>
										<ol>
											<li>
												Učesnici, koji budu proglašeni za dobitnike Dnevne, Nedeljne i Mesečne nagrade, biće kontaktirani u roku od 15 radna dana od objave rezultata na Rang listi putem e-maila koje su Učesnici dostavili prilikom registracije (u daljem tekstu: <strong>Obaveštenje</strong>), a u cilju dodele nagrade. Kontaktirani Učesnici imaju rok od 3 (tri) dana da odgovorom na Obaveštenje potvrde da prihvataju nagradu kao i da potvrde svoje lične podatke: ime, prezime, adresu stanovanja, JMBG i broj telefona. Ukoliko Učesnik ne odgovori na Obaveštenje u roku od 3 (tri) dana Organizator će još jednom pokušati da kontaktira Učesnike putem broja tefefona, koji su Učesnici dostavili prilikom registracije, a u cilju prikupljanja podataka za dodelu nagrade. Ukoliko u navedenom roku Učesnik, koji bude proglašen za dobitnika, ne odgovori na Obaveštenje ili ne dostavi tražene podatke smatraće se da isti ne prihvata nagradu i neće imati nikakva prava prema Organizatoru po osnovu ovog Konkursa, a nagrada će ostati nedodeljena.

											</li>
											<li>
												O datumu korišćenja Glavne nagrade Organizator će obavestiti Učesnika prilikom Obaveštenja o osvojenoj nagradi.
											</li>
											<li>
												Nakon što Učesnik, koji bude proglašen za dobitnika Ostale nagrade, dostavi sve neophodne podatke za dostavu nagrade, Organizator će obavestiti dobitnika o datumu predaje odnosno korišćenja osvojene Ostale nagrade. Isporuka navedenih nagrada će biti izvršena najkasnije 30 dana nakon Obaveštenja. Nagrade će biti poslate na kućne adrese dobitnika kurirskom službom, koja će kontaktirati dobitnike nagrada. Organizator ne snosi odgovornost u slučaju da kurirska služba ne može da stupi u kontakt sa dobitnikom, iz razloga koji ne spadaju u odgovornost Organizatora. Organizator Konkursa nije u mogućnosti da utiče na raspored dostave poklona i ne snosi odgovornost ukoliko dobitnik nije na adresi u trenutku dostave. Slanje nagrada je besplatno za dobitnika. Ukoliko dobitnik ne bude dostupan kurirskoj službi u dva pokušaja, smatraće se da dobitnik ne prihvata nagradu i Organizator nije u obavezi da mu istu naknadno dostavi.
											</li>
											<li>
												Prilikom preuzimanja osvojene nagrade, dobitnik dokazuje svoj identitet podnošenjem na uvid važećeg ličnog dokumenta iz kog je vidljivo ime, prezime i JMBG lica koje je prijavljeno prilikom Obaveštavanja. Ukoliko je iz ličnog dokumenta vidljivo da potencijalni dobitnik nije državljanin Republike Srbije, nema prebivalište na teritoriji Republike Srbije ili nije punoletan, nagrada će ostati nedodeljena. 
											</li>
											<li>Učesnici koji su dali netačne ili nepotpune podatke gube pravo na nagradu.</li>
											<li>Učesnici koji su dali netačne ili tuđe podatke isključivo odgovaraju za takvu zloupotrebu podataka.</li>
											<li>
												Učesnici koji nisu u mogućnosti da prilikom preuzimanja nagrada ili na zahtev Organizatora predaju račune koji su bili osnov za igranje Kviza i učestvovanje u Konkursu, gube pravo na nagradu.
											</li>
											<li>
												U slučaju da iz bilo kog razloga dobitnik ne može da preuzme nagradu ista će, u tom slučaju, ostati nedodeljena. 
											</li>
											<li>
												Organizator ne snosi odgovornost u slučaju da ne može da stupi u kontakt sa dobitnikom  iz razloga koji ne spadaju u odgovornost Organizatora, kao ni u slučaju da se dobitnik ne oglasi, kako bi potvrdio da je obavešten o prijemu nagrade.
											</li>
											<li>
												Sve nagrade su neprenosive, a u slučaju da dobitnik nije u mogućnosti da ove nagrade iskoristi, nema pravo da iste prenese na treća lica.
											</li>
											<li>
												Sve ostale detalje u vezi sa Nagradom, a koji ovde nisu navedeni, Organizator će blagovremeno saopštiti dobitniku.
												</li>
										</ol>
									</li>
									<li><h4>Lični podaci</h4>
										<ol>
											<li>
											Učestvovanjem na Konkursu svaki Učesnik prihvata prava i obaveze iz ovih Pravila i daje svoju izričitu saglasnost da prima e-mail poštu, SMS poruke na mobilni telefon i telefonske pozive na mobilni broj telefona u vezi sa učestvovanjem na Konkursu i njegovom organizacijom, uključujući i saglasnost za primanje poruka i informacija u svrhe promocije Konkursa i proizvoda Organizatora.
											</li>
											<li>
											Učesnici na Konkursu pristaju da se podaci i materijali koji sadrže lične podatke i zapise, koje stavljaju na raspolaganje Organizatoru i to putem prijave na Konkurs, čuvaju, beleže i objavljuju i na drugi način obrađuju isključivo u skladu sa Zakonom o zaštiti podataka o ličnosti, drugim primenjivim zakonima i ovim Pravilima i u svrhu učešća na Konkursu, i isključivo od strane ovlašćenih lica zaposlenih kod Organizatora.
											</li>
											<li>
											Svim dobitnicima Glavne nagrada će biti ponuđen formular-pristanak da se njihova imena i lični podaci javno objave, bez vremenskog i teritorijalnog ograničenja, u skladu sa važećim propisima, kao što su: ime, prezime, slika, video materijal, izjave, zapisi i ostali podaci koji se odnose na njihovu ličnost, a u vezi su sa Konkursom. Takođe, ova izjava će sadržati saglasnost da se navedeni podaci mogu koristiti od strane Organizatora tako da se objave u štampanom, zvučnom, slikovnom i video materijalu bez naknade, da mogu da se umnožavaju u neograničenom broju primeraka i ponovo objavljuju, kako za vreme trajanja tako i nakon isteka Konkursa, a sve u cilju promocije proizvoda Organizatora.
											</li>
											<li>
											Svi podaci o dobitnicima se čuvaju i obrađuju u skladu sa Zakonom o zaštiti podataka o ličnosti, do 01.02.2024. godine i to za svrhu prijave poreza. Nakon ovog datuma, svi podaci se nepovratno brišu.
											</li>
											<li>
												Lice za zaštitu podataka o ličnosti Organizatora je Mihailo Đuričić, e-mail adresa: <a href="mailto:mihailo.djuricic@heineken.com" rel="noopener noreferrer">mihailo.djuricic@heineken.com</a>
											</li>
										</ol>
									</li>
									<li><h4>Isključenje i ograničenje odgovornosti</h4>
										<ol>
											<li>
											U slučaju primedbi na kvalitet i upotrebnu vrednost nagrada, dobitnici se mogu obratiti pravnim licima koja su nagrade obezbedili u skladu sa propisima o zaštiti potrošača. Za sve nagrade, odgovornost Organizatora prema dobitnicima prestaje nakon čina isporuke/predaje osvojene nagrade koja odgovara opisu nagrade iz ovih Pravila.
											</li>
										</ol>
									</li>
									<li><h4>Pitanja u vezi sa Konkursom</h4>
										<ol>
											<li>
											Učesnici Konkursa mogu postavljati pitanja u vezi sa Konkursom na sledeći info mail <a href="mailto:birramoretti.konkurs@gmail.com" rel="noopener noreferrer">birramoretti.konkurs@gmail.com</a> ili pozivom na broj <a href="tel:0800100155">0800/100 155</a>. Radno vreme korisničkog servisa je radnim danima od 09:00 do 17:00.
											</li>
										</ol>
									</li>
									<li><h4>Završne odredbe</h4>
										<ol>
											<li>
											Konkurs se može prekinuti u slučaju da nastupe okolnosti za koje Organizator nije odgovoran, odnosno koje nije mogao predvideti, sprečiti, otkloniti ili izbeći, a koje bitno utiču na sprovođenje i realizaciju Konkursa.
											</li>
											<li>
											Učesnici će o eventualnom prekidu Konkursa biti obavešteni putem Web stranice.
											</li>
											<li>
											Organizator zadržava pravo da u bilo koje vreme, iz opravdanih razloga, izvrši izmene ovog Konkursa, uz obavezu da iste, bez odlaganja, objavi na Web stranici.

											</li>
											<li>
											Organizator ne preuzima odgovornost u slučaju spora između Učesnika Konkursa u vezi sa vlasništvom nad poslatim kodom, sim kartice sa koje je komunicirano, e-mail adrese sa koje je izvršena registracija, te će Učesnik koji je koristio kod ili sim karticu na kojoj postoje prava trećih lica biti isključivo odgovoran i obeštetiće kako ta treća lica, tako i Organizatora. 
											</li>
											<li>
											U slučaju spora između Organizatora i Učesnika Konkursa nadležan je sud u Beogradu.
											</li>
											<li>
											Ova Pravila objavljuju se na Web stranici <a href="https://www.birramoretti.com/rs/sr/birramorettinagradjuje" target="_blank" rel="noopener noreferrer">www.birramoretti.com/rs/sr/birramorettinagradjuje</a>.
											</li>
										</ol>
									</li>
								</ol>
								</div>
								                            
                            </Grid>
							<Grid item xs></Grid>
                        </Grid>                        
					</section>
				</div>
						<div className={classes.SpinnerHolder}>
							{spin}
						</div>
			</Hoc >
		);
	}
};

const mapStateToProps = state => {
	return {
						rdy: state.main.landingPageReady,
		spin: state.main.spinnerState,
		lpElement: state.main.landingPageElement,
		curPath: state.main.currentPath,
		lp: state.main.landingPagePosition
	};
}

const mapDispatchToProps = dispatch => {
	return {
						onRdyLanding: () => dispatch(actions.landingpageReady()),
		spinnerChange: (spin) => dispatch(actions.spinnerState(spin)),
		onUpdatePath: (newpath) => dispatch(actions.changePath(newpath)),
	}
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pravila));