import React, { Component } from "react";
import "./App.css";

import Layout from "./components/Layout/Layout";
import LandingPage from "./containers/LandingPage/LandingPage";
import verifyAccount from "./containers/verifyAccount/verifyAccount";
import forgotPassword from "./containers/forgotPassword/forgotPassword";
import Nagrade from "./containers/Nagrade/Nagrade";
import Kako from "./containers/Kako/Kako";
import Activation from "./containers/Activation/Activation";
import Dobitnici from "./containers/Dobitnici/Dobitnici";
import Pravila from "./containers/Pravila/Pravila";

import Profile from "./containers/Profile/Profile";

import { Route, Switch, withRouter, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import * as actions from "./store/actions/index";

class App extends Component {
  componentDidMount() {
    window.addEventListener("load", this.handleLoad);
    if (
      !this.props.curPath.includes("verifyAccount") &&
      !this.props.curPath.includes("forgotPassword")
    ) {
      this.props.onTryAutoSignup();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.handleLoad);
  }

  handleLoad = () => {
    console.log(window.location.href);
    if (
      !window.location.href.includes("verifyAccount") &&
      !window.location.href.includes("forgotPassword") &&
      !window.location.href.includes("localhost")
    ) {
      if (window.self === window.top) {
        window.location.href =
          "https://www.birramoretti.com/rs/sr/birramorettinagradjuje";
      }
    }
  };

  render() {
    if (
      this.props.firstLoad === null &&
      !this.props.curPath.includes("verifyAccount") &&
      !this.props.curPath.includes("forgotPassword")
    )
      return <div></div>;

    let routes = (
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/verifyAccount/:hash" component={verifyAccount} />
        <Route path="/forgotPassword/:hash" component={forgotPassword} />
        <Route path="/nagrade" component={Nagrade} />
        <Route path="/kako-da-ucestvujem" component={Kako} />
        <Route path="/birra-moretti-nagradjuje" component={Activation} />
        <Route path="/dobitnici" component={Dobitnici} />
        <Route path="/kontakt-i-pravila" component={Pravila} />
        <Redirect to="/" />
      </Switch>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <Route path="/nagrade" component={Nagrade} />
          <Route path="/kako-da-ucestvujem" component={Kako} />
          <Route path="/birra-moretti-nagradjuje" component={Activation} />
          <Route path="/dobitnici" component={Dobitnici} />
          <Route path="/kontakt-i-pravila" component={Pravila} />
          <Route path="/profil" component={Profile} />
          <Redirect to="/" />
        </Switch>
      );
    }

    return <Layout path={this.props.curPath}>{routes}</Layout>;
  }
}

const mapStateToProps = (state) => {
  return {
    firstLoad: state.auth.firstLoad,
    isAuthenticated: state.auth.token !== null,
    curPath: state.main.currentPath,
    authToken: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onAuthCheckState: (t, ed, uid, a) =>
      dispatch(actions.authCheckState(t, ed, uid, a)),
    onSetAvatar: (a, at) => dispatch(actions.setAvatar(a, at)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
