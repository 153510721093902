import React, { Component } from 'react';
import classes from './Kako.module.css';

import Spinner from '../../components/UI/LoadingSpinner'
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import Hoc from '../../hoc/hoc';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

class Kako extends Component {

	componentWillMount() {
        this.props.spinnerChange(true);
        if (this.props.curPath!=='/kako-da-ucestvujem') {
            this.props.onUpdatePath('/kako-da-ucestvujem');
        }
	}

	componentDidMount() {
		this.props.onRdyLanding();
		this.props.spinnerChange(false);
	};

	render() {

		let spin = null;
		if (this.props.spin) {
			spin = <Spinner />
		}

		return (
			<Hoc>
				<div className={classes.container} location={this.props.curPath}>
					<section id="container">
                        <Grid container spacing={0} className={classes.grid}>
							<Grid item xs></Grid>
                            <Grid item xs={11} md={8}>
                            <h1>Kako da učestvujem?</h1>
                                <h3>Kupite pivo</h3>
                                <p>Kupite 3 (tri) Birra Moretti piva u pakovanjima 0,5l limenka, 0,<span style={{marginLeft:"0.0001rem"}}>4l</span> povratna boca, 0,33l nepovratna boca, 0,33l limenka u maloprodajnim objektima Idea, Roda, Mercator. Sačuvajte račun jer se na njemu nalazi kod koji možete upisati na sajtu i tako prikupiti poene.</p>

                                <div className={classes.divider}></div>

                                <h3>Registrujte se</h3>
                                <p>Potrebno je da se registrujete i na taj način kreirate svoj nalog. Svaki sledeći put kada želite da učestvujete, biće dovoljno da se prijavite sa svojom <span className={classes.nobreak}>e-mail</span> adresom i lozinkom.</p>

                                <div className={classes.divider}></div>

                                <h3>Informišite se</h3>
                                <p>Toplo vam preporučujemo da pročitate <span className={classes.nobreak}>
                                <Link
                                    className={classes.pravilaLink}
									href="#"
									onClick={() => { 
										this.props.history.push('/kontakt-i-pravila');
										this.props.onUpdatePath('/kontakt-i-pravila');
									}}
									
                                >"Pravila nagradnog konkursa"</Link></span> u kojima ćete naći detaljno objašnjenje kako učestvovati, ko može da se prijavi, koji su kriterijumi za osvajanje nagrada, dinamika dodeljivanja nagrada i mnoge druge korisne informacije.</p>

                                <div className={classes.divider}></div>

                                <h3>Unesite kodove</h3>
                                <p>Unesite <span className={classes.nobreak}><strong><Link
                                    className={classes.pravilaLink}
									href="#"
									onClick={() => { 
										this.props.history.push('/birra-moretti-nagradjuje');
										this.props.onUpdatePath('/birra-moretti-nagradjuje');
									}}
									
                                >PFR kod</Link></strong></span> sa računa, slikajte i pošaljite fotografiju računa na kojoj se jasno vide kupljeni Birra Moretti artikli i PFR kod računa, zatim odgovorite tačno na pitanje. Na taj način sakupljate poene i možete osvojiti vredne nagrade.<br/>
								<img className={classes.pfrslika} src="/pfr-example.jpg" alt="Slika PFR broja računa" />
								</p>
								<div className={classes.divider}></div>
								<h3>Sačuvajte sve račune</h3>
								<p>
								Sačuvajte sve račune sa kojih ste uneli PFR kod, i to tako da je svaki fiskalni račun očuvan do reči <span className={classes.nobreak}>"KRAJ FISKALNOG RAČUNA"</span>, u slučaju da je neophodno predati iste na uvid radi provere, bez obzira na to za koju se nagradu takmičite.
								</p>
                            </Grid>
                            <Grid item xs></Grid>
                        </Grid>                        
					</section>
				</div>
				<div className={classes.SpinnerHolder}>
					{spin}
				</div>
			</Hoc >
		);
	}
};

const mapStateToProps = state => {
	return {
		rdy: state.main.landingPageReady,
		spin: state.main.spinnerState,
		lpElement: state.main.landingPageElement,
		curPath: state.main.currentPath,
		lp: state.main.landingPagePosition
	};
}

const mapDispatchToProps = dispatch => {
	return {
		onRdyLanding: () => dispatch(actions.landingpageReady()),
		spinnerChange: (spin) => dispatch(actions.spinnerState(spin)),
		onUpdatePath: (newpath) => dispatch(actions.changePath(newpath)),
	}
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Kako));