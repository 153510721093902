import React, { Component } from 'react';
import classes from './Activation.module.css';
import Hoc from './../../hoc/hoc';
import Countdown from './Countdown/Countdown';

import IconButton from '@material-ui/core/IconButton';
import Save from '@material-ui/icons/Save';
import CodePhoto from 'react-easy-crop';
import CancelIcon from '@material-ui/icons/Cancel';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getRotatedImage } from './canvasUtils'

import { Button, Stepper, Step, StepLabel, Paper } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress'

import ReactGA from 'react-ga';

import { withRouter } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';

function fisherYates(array) {
    var count = array.length,
        randomnumber,
        temp;
    while (count) {
        randomnumber = Math.random() * count-- | 0;
        temp = array[count];
        array[count] = array[randomnumber];
        array[randomnumber] = temp
    }
    return array;
}

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

class Activation extends Component {

    state = {
        activeStep: 0,
        steps: [
            'Unesite kod',
            'Pošaljite fotografiju koda',
            'Odgovorite na pitanje'
        ],
        kod1: '',
        kod2: '',
        kod3: '',
        // kod: randomString(17, "n"),
        isDisabled: true,
        isSubmitted: false,
        kodStatus: null,
        kodId: null,
        buttonPressed: null,

        questions: [
          {
            question: "Originalna receptura Birra Moretti piva razvijena je davne:",
            wrongAnswer1: "1946. godine",
            wrongAnswer2: "1957. godine",
            correctAnswer: "1859. godine",
          },
          {
            question:
              "Pre više od veka, Luiđi Moreti želeo je da stvori pivo koje će se razlikovati od ostalih, te je pokrenuo pivaru kom gradu na severu Italije:",
            correctAnswer: "Udinama",
            wrongAnswer1: "Milanu",
            wrongAnswer2: "Trstu",
          },
          {
            question:
              "Birra Moretti neguje duh italijanskih proizvoda prepoznatljivog kvaliteta i ukusa, te je zato pivo broj jedan u",
        
            correctAnswer: "Italiji",
            wrongAnswer1: "Švedskoj",
            wrongAnswer2: "Grčkoj",
          },
          {
            question:
              "Za proizvodnju Birra Moretti piva koriste se najbolje sirovine, a spada u kategoriju:",
            correctAnswer: "Svetlog piva",
            wrongAnswer1: "Tamnog piva",
            wrongAnswer2: "Kraft piva",
          },
        
          {
            question:
              "Ritual aperitiva koji se vezuje za period dana između 18 i 20h - vreme za uživanje u malim zadovoljstvima nakon radnog vremena, svojstven je za:",
        
            correctAnswer: "Italiju",
            wrongAnswer1: "Nemačku",
            wrongAnswer2: "Češku",
          },
          {
            question: "Koliko procenata alkohola sadrži Birra Moretti pivo?",
        
            correctAnswer: "4.6%",
            wrongAnswer1: "3%",
            wrongAnswer2: "Ne sadrži alkohol",
          },
          {
            question: "Osnivač Birra Moretti piva zvao se ...",
        
            wrongAnswer1: "Leonardo Moretti",
            correctAnswer: "Luiđi Moretti",
            wrongAnswer2: "Marco Moretti",
          },
        
          {
            question: "Moretti je svoju fabriku osnovao u ...",
        
            wrongAnswer1: "Rimu",
            wrongAnswer2: "Milanu",
            correctAnswer: "Udinama",
          },
          {
            question: "Zaštitni znak Bira Moretti piva je?",
        
            correctAnswer: "Čovek sa brkovima",
            wrongAnswer1: "Krigla piva",
            wrongAnswer2: "Papa",
          },
        
          {
            question: 'Kako se na italijanskom kaže"prijatno"?',
        
            wrongAnswer1: "Arrivederci",
            correctAnswer: "Buon appetito",
            wrongAnswer2: "Buona Notte",
          },
          {
            question: "U kom italijanskom gradu se održva veliki karneval?",
        
            correctAnswer: "U Veneciji",
            wrongAnswer1: "U Milanu",
            wrongAnswer2: "U Napulju",
          },
        
          {
            question: 'Kako se na italijanskom kaže"živeli"?',
        
            correctAnswer: "Salute",
            wrongAnswer1: "Sante",
            wrongAnswer2: "Proost",
          },
          {
            question:
              "Posebna mešavina jednog sastojka daje Birra Moretti pivu jedinstveni ukus i miris, obogaćujući njegov savršeno uravnoteženi gorki ukus. Koji je to sastojak?",
        
            correctAnswer: "Visokokvalitetni hmelj",
            wrongAnswer1: "Voda",
            wrongAnswer2: "Kvasac",
          },
          {
            question: "Koji sastojci idu u Margarita pizzu?",
        
            correctAnswer: "Pelat, Mocarela i bosiljak",
            wrongAnswer1: "Pršuta, pelat, paprika i origano",
            wrongAnswer2: "Pelat, Gorgonzola, kulen i rukola",
          },
          {
            question:
              'Pored pice i paste, Italija je poznata i po nečemu što ima naziv"gelato"? Šta je to?',
        
            wrongAnswer1: "Voćni kup",
            correctAnswer: "Sladoled",
            wrongAnswer2: "Krempita",
          },
          {
            question: "Koji tri boje možemo pronaći na zastavi Italije?",
        
            wrongAnswer1: "Žutu, plavu i belu",
            correctAnswer: "Zelenu, belu i crvenu",
            wrongAnswer2: "Crvenu, plavu i belu",
          },
        
          {
            question:
              "Koja dva italijanska fudbalska kluba dele u Milanu isti stadion?",
        
            correctAnswer: "Inter i Milan",
            wrongAnswer1: "Inter i Lazio",
            wrongAnswer2: "Milan i Lazio",
          },
        
          {
            question: "Koje je italijansko nacionalno jelo?",
        
            wrongAnswer1: "Gulaš",
            correctAnswer: "Bolonjeze špagete",
            wrongAnswer2: "Suvlaki",
          },
          {
            question: "Koji je osnovni sastojak njoka?",
        
            correctAnswer: "Krompir",
            wrongAnswer1: "Kukuruz",
            wrongAnswer2: "Grašak",
          },
          {
            question:
              "Sladovina, koja se koristi u pripremi Birra Moretti piva se kuva na koliko stepeni?",
        
            correctAnswer: "100",
            wrongAnswer1: "30",
            wrongAnswer2: "5",
          },
          {
            question:
              "U kom delu procesa proizvodnje se definište boja i ukus Birra Moretti piva?",
        
            wrongAnswer1: "Dodavanje vode",
            wrongAnswer2: "Uključivanje kvasca",
            correctAnswer: "Prženje slada",
          },
          {
            question: "U kom italijanskom gradu je nastala pica?",
        
            wrongAnswer1: "Rim",
            correctAnswer: "Napulj",
            wrongAnswer2: "Firenca",
          },
        
          {
            question: "Koja država se nalazi u Rimu?",
        
            wrongAnswer1: "San Marino",
            wrongAnswer2: "Andora",
            correctAnswer: "Vatikan",
          },
          {
            question: "Kako se kaže pivo na italijanskom?",
        
            correctAnswer: "Birra",
            wrongAnswer1: "Bier",
            wrongAnswer2: "Beer",
          },
          {
            question: "Uz koja jela se Birra Moretti pivo najbolje slaže?",
        
            correctAnswer:
              "Izbalansirani ukus Birra Morettija čini ga savršenim prilogom uz bilo koje jelo",
            wrongAnswer1: "Slatkiše",
            wrongAnswer2: "Salate",
          },
          {
            question:
              "Kako se naziva ritual u Italiji koji je svojstven za uživanje u malim zadovoljstvima nakon posla od 18 do 20h?",
        
            wrongAnswer1: "Branč",
            correctAnswer: "Aperitivo",
            wrongAnswer2: "Matine",
          },
        
          {
            question: 'Kako se na italijanskom kaže "zbogom"?',
        
            correctAnswer: "Arrivederci",
            wrongAnswer1: "Buon appetito",
            wrongAnswer2: "Buona Notte",
          },
          {
            question: "Kako se na italijanskom kaže slatki život?",
        
            correctAnswer: "Dolce vita",
            wrongAnswer1: "Dolce gusto",
            wrongAnswer2: "Dolce",
          },
        
          {
            question: 'Šta na italijanskom znači"Dolce far niente"?',
        
            correctAnswer: "Sladak nerad",
            wrongAnswer2: "Težak rad",
            wrongAnswer1: "Nadareni umetnik",
          },
          {
            question: 'Ko je rekao:"Kvalitet piva ogleda se u kvalitetu sastojaka?"',
        
            wrongAnswer1: "Đuzepe Garibaldi",
            correctAnswer: "Luigi Morreti",
            wrongAnswer2: "Sergio Mattarella",
          },
        
          {
            question:
              "U procesu proizvodnje Birra Moretti piva kada se ječam pomeša sa vodom, počinje da klija i postaje:",
        
            correctAnswer: "Slad",
            wrongAnswer1: "Komina",
            wrongAnswer2: "Koncentrat za proizvodnju piva",
          },
          {
            question:
              "Jedan od koraka u proizvodnji Birra Moretti piva je sušenje slada čime prestaje njegovo klijanje i spušta se vlažnost na:",
        
            correctAnswer: "5%",
            wrongAnswer1: "6%",
            wrongAnswer2: "7%",
          },
        
          {
            question:
              "U procesu proizvodnje Birra Moretti piva slad se dobija mešavinom koja dva sastojka?",
        
            correctAnswer: "Ječam i voda",
            wrongAnswer1: "Hmelj i kvasac",
            wrongAnswer2: "Hmelj i voda",
          },
          {
            question:
              "U procesu proizvodnje Birra Moretti piva mešavinom i zagrevanjem samlevenog slada i krupice se dobija...",
        
            correctAnswer: "Komina",
            wrongAnswer1: "Hmelj",
            wrongAnswer2: "Kvasac",
          },
        
          {
            question:
              "U procesu proizvodnje Birra Moretti piva tokom kuvanja sladovine na 100 stepeni, hmelj se:",
        
            correctAnswer: "dodaje više puta",
            wrongAnswer1: "jedan put",
            wrongAnswer2: "ne dodaje",
          },
          {
            question:
              "Šta se u procesu proizvodnje Birra Moretti piva dodaje u ohlađenu sladovinu koja se nalazi u fermentoru?",
        
            correctAnswer: "Kvasac",
            wrongAnswer1: "Hmelj",
            wrongAnswer2: "Komina",
          },
        
          {
            question: "Poslednji korak u proizvodnji Birra Moretti piva je...",
        
            correctAnswer: "Filtracija - uklanjanje kvasca",
            wrongAnswer1: "Dodavanje vode",
            wrongAnswer2: "Dodavanje hmelja",
          },
          {
            question:
              "Kako se zvala fabrika piva Luiđi Moretija osnovana u Udinama pre više od jednog veka?",
        
            wrongAnswer1: "Fabrika piva",
            wrongAnswer2: "Fabrika prerade hmelja",
            correctAnswer: "Fabrika piva i leda",
          },
        
          {
            question:
              'U kojoj regiji Italije je osnovana"Fabrika piva i Leda"Luiđija Moretija?',
        
            wrongAnswer1: "Apulija",
            wrongAnswer2: "Lombardija",
            correctAnswer: "Furlanija",
          },
          {
            question: "Prva boca Birra Moretti piva se našla na tržištu...",
        
            wrongAnswer1: "Jesen 1872.",
            wrongAnswer2: "Proleće 1890.",
            correctAnswer: "Leto 1860.",
          },
        
          {
            question: "Nacionalna distribucija Birra Moretti piva počinje:",
        
            wrongAnswer1: "Iz Furlanije 1870-ih",
            wrongAnswer2: "Iz Furlanije 1890-ih",
            correctAnswer: "Iz Furlanije 1990-ih",
          },
          {
            question: "Prave vrednosti Birra Moretti piva su:",
        
            wrongAnswer1: "Tradicija i kultura",
            wrongAnswer2: "Kultura i autentičnost",
            correctAnswer: "Autentičnost, tradicija i istina",
          },
        
          {
            question:
              "Koje Italijansko pivo je 2006. godine bio jedini italijanski brend koji je nagrađen zlatnim i srebrnim medaljama na World Beer Cup-u?",
        
            wrongAnswer1: "My Antonia",
            correctAnswer: "Birra Moretti",
            wrongAnswer2: "Heineken",
          },
        
          {
            question:
              "Koje italijansko pivo je osvajalo prva mesta na najvažnijim međunarodnim takmičenjima u kategorijama kojima dominiraju nemačka ili američka piva.",
        
            wrongAnswer1: "Laško",
            correctAnswer: "Birra Moretti",
            wrongAnswer2: "My Antonia",
          },
          {
            question:
              "Brusketi - prepečen hleb sa natopljen maslinovim uljem, belim lukom i solju je predjelo koje je nastalo u:",
        
            correctAnswer: "Italiji",
            wrongAnswer1: "Nemačkoj",
            wrongAnswer2: "Makedoniji",
          },
        
          {
            question:
              "Čovek na slici koji se nalazi na svakoj etiketi piva Birra Moretti je:",
        
            wrongAnswer1: "Osnivač Luiđi Moreti",
            wrongAnswer2: "Nećak Luiđi Moretija",
            correctAnswer: "Nepoznati čovek",
          },
          {
            question:
              "Slika nepoznatog čoveka koji se nalazi na svakoj etiketi piva Birra Moretti fotografisao je:",
        
            correctAnswer: "Nećak Luiđija Moretija",
            wrongAnswer1: "Luiđi Moretti",
            wrongAnswer2: "Barmen",
          },
        
          {
            question:
              "Nepoznati čovek koji se nalazi na svakoj etiketi piva Birra Moretti je dozvolio da ga slikaju u zamenu za:",
        
            correctAnswer: "Jedno Birra Moretti pivo",
            wrongAnswer1: "Ručak",
            wrongAnswer2: "Primerak fotografije",
          },
          {
            question: "Birra Moretti pivo je vodeći brend u Italiji i izvozi se u:",
        
            correctAnswer: "preko 40 zemalja širom sveta",
            wrongAnswer1: "30 zemalja širom sveta",
            wrongAnswer2: "20 zemalja širom sveta",
          },
        
          {
            question: "Na koji oblik obuće podesća kontinentalni oblik države Italija?",
        
            wrongAnswer1: "Papuča",
            wrongAnswer2: "Patika",
            correctAnswer: "Čizma",
          },
          {
            question:
              "Jedan od najpoznatijih namaza vodi poreklo iz Italije. Koji je to namaz?",
        
            wrongAnswer1: "Džem",
            correctAnswer: "Nutella",
            wrongAnswer2: "Marmelada",
          },
        
          {
            question: "Koja država ima tri aktivna vulkan: Vezuv, Etna i Stromboli?",
        
            wrongAnswer1: "Španija",
            correctAnswer: "Italija",
            wrongAnswer2: "Francuska",
          },
          {
            question:
              "Najstariji filmski festival na svetu koji se održava od 1932. godine je?",
        
            wrongAnswer1: "filmski festival u Milanu",
            correctAnswer: "filmski festival u Veneciji",
            wrongAnswer2: "filmski festival u Torinu",
          },
        
          {
            question:
              "Najstariji filmski festival na svetu je filmski festival u Veneciji koji se održava od?",
        
            wrongAnswer1: "1930. godine",
            correctAnswer: "1932. godine",
            wrongAnswer2: "1934. godine",
          },
          {
            question:
              "Krivi toranj u Pizi je jedna od glavnih atrakcija za turiste koji posećuju:",
        
            correctAnswer: "Italiju",
            wrongAnswer1: "Sloveniju",
            wrongAnswer2: "Nemačku",
          },
        
          {
            question: "Koliko stepenika do vrha ima Krivi toranj u Pizi?",
        
            correctAnswer: "296 na višoj i 294 na nižoj strani",
            wrongAnswer1: "298 na višoj i 296 na nižoj strani",
            wrongAnswer2: "300 na višoj i 298 na nižoj strani",
          },
          {
            question: "Koliko zvona ima Krivi toranj u Pizi?",
        
            wrongAnswer1: "6",
            correctAnswer: "7",
            wrongAnswer2: "8",
          },
          {
            question: "Koliko kg testenine prosečan Italijan godišnje pojede?",
        
            wrongAnswer1: "20",
            correctAnswer: "25",
            wrongAnswer2: "30",
          },
        
          {
            question:
              "U Italiji se nalazi najbogatija fontana na svetu. Kako se ona zove?",
        
            wrongAnswer1: "Arčibaldova fontana",
            correctAnswer: "Fontana di Trevi",
            wrongAnswer2: "Fontana bogatstva",
          },
        
          {
            question:
              "Koji grad u Italiji broji 177 kanala po kojima se saobraća gondolama, čamcima, malim parobrodima i motornim brodovima?",
        
            correctAnswer: "Venecija",
            wrongAnswer1: "Trst",
            wrongAnswer2: "Rim",
          },
          {
            question: "Koliko kanala po kojima se odvija saobraćaj ima Venecija?",
        
            correctAnswer: "177",
            wrongAnswer1: "185",
            wrongAnswer2: "198",
          },
        
          {
            question: "Koji grad u Italiji je izgrađen na 118 malih ostrva u laguni?",
        
            wrongAnswer1: "Trst",
            correctAnswer: "Venecija",
            wrongAnswer2: "Milano",
          },
          {
            question: "Na koliko malih ostrva u laguni je izgrađena Venecija?",
        
            correctAnswer: "118",
            wrongAnswer1: "129",
            wrongAnswer2: "130",
          },
        
          {
            question:
              "Nutela, Ferari i Versaće su jedni od najvećih brendova, a vode poreklo iz ?",
        
            wrongAnswer1: "Belgije",
            correctAnswer: "Italije",
            wrongAnswer2: "Francuske",
          },
        
          {
            question: "Za koji broj se smatra da je nesrećan u Italiji?",
        
            wrongAnswer1: "13",
            correctAnswer: "17",
            wrongAnswer2: "23",
          },
        
          {
            question:
              "Zakonom je uređeno da sve gondole u Veneciju moraju biti koje boje?",
        
            wrongAnswer1: "Crvene",
            correctAnswer: "Crne",
            wrongAnswer2: "Plave",
          },
          {
            question: "U Italijanskom gradu Parma je nastao čuveni sir:",
        
            wrongAnswer1: "Trapist",
            correctAnswer: "Parmezan",
            wrongAnswer2: "Edamer",
          },
        
          {
            question:
              "Gorgonzola, mocarela, parmezan i rikota su čuveni sirevi koji vode poreklo iz:",
        
            wrongAnswer1: "Engleske",
            correctAnswer: "Italije",
            wrongAnswer2: "Francuske",
          },
          {
            question: 'Autor popularnog dečijeg dela"Pinokio", Carlo Collodi bio je:',
        
            wrongAnswer1: "Englez",
            correctAnswer: "Italijan",
            wrongAnswer2: "Francuz",
          },
        
          {
            question: 'Italijanski autor popularnog dečijeg dela"Pinokio" je:',
        
            correctAnswer: "Carlo Collodi",
            wrongAnswer1: "Dino Bucati",
            wrongAnswer2: "Antonio Skurati",
          },
          {
            question: "Čuveni Italijanski kolači koji se prave sa rikotom su:",
        
            wrongAnswer1: "Tiramisu",
            correctAnswer: "Kanoli",
            wrongAnswer2: "Rum Baba",
          },
        
          {
            question: "Najstariji univerzitet u Evropi iz 1088. godine je:",
        
            wrongAnswer1: "Oksfordski univerzitet",
            correctAnswer: "Univerzitet u Bolonji",
            wrongAnswer2: "Univerzitet u Kembridžu",
          },
          {
            question:
              "Ko je od 1508. do 1512. godine oslikao 1.100m² tavanice Sikstinske kapele:",
        
            wrongAnswer1: "Pikaso",
            correctAnswer: "Mikelanđelo",
            wrongAnswer2: "Peruđino",
          },
        
          {
            question: "Koliko ukupno mostova postoji u Veneciji:",
        
            correctAnswer: "417",
            wrongAnswer1: "5",
            wrongAnswer2: "30",
          },
          {
            question: "Oko 3.000 evra se izvuče svakog dana iz:",
        
            wrongAnswer1: "Arčibaldova fontana",
            correctAnswer: "Fontana di Trevi",
            wrongAnswer2: "Fontana bogatstva",
          },
          {
            question: "Smatra se da se prava Italijanska pica peče:",
        
            wrongAnswer1: "U rerni",
            correctAnswer: "Na drva",
            wrongAnswer2: "Na struju",
          },
        
          {
            question: "Dve nezavisne države u Italiji su:",
        
            wrongAnswer1: "Milano i Trst",
            correctAnswer: "San Marino i Vatikan",
            wrongAnswer2: "Sevilja i Verona",
          },
          {
            question: "Koliko približno turista dođe u Italiju svake godine:",
        
            wrongAnswer1: "30 miliona",
            wrongAnswer2: "40 miliona",
            correctAnswer: "50 miliona",
          },
        
          {
            question: "Dezert koji je izmišljen u Italiji je:",
        
            wrongAnswer1: "Krem brule",
            wrongAnswer2: "Čokoladni tartufi",
            correctAnswer: "Tiramisu",
          },
          {
            question: "Koliko otprilike svaki Italijan godišnje popije litara vina:",
        
            wrongAnswer1: "13",
            wrongAnswer2: "19",
            correctAnswer: "26",
          },
        
          {
            question: "Koji sport se u Italiji smatra nacionalnim sportom:",
        
            correctAnswer: "Fudbal",
            wrongAnswer1: "Rukomet",
            wrongAnswer2: "Odbojka",
          },
          {
            question: "Opera se prvi put čula u:",
            wrongAnswer1: "Francuskoj",
            wrongAnswer2: "Nemačkoj",
            correctAnswer: "Italiji",
          },
        
          {
            question:
              "Otprilike 25kg testenine godišnje pojede svaka osoba koja živi u:",
        
            wrongAnswer1: "Engleskoj",
            wrongAnswer2: "Nemačkoj",
            correctAnswer: "Italiji",
          },
          {
            question: "Violončelo i violinu su stvorili:",
        
            wrongAnswer1: "Francuzi",
            wrongAnswer2: "Nemci",
            correctAnswer: "Italijani",
          },
        
          {
            question: "Koliko puta je Italija bila domaćin Olimpijskih igara:",
        
            wrongAnswer1: "1",
            wrongAnswer2: "2",
            correctAnswer: "3",
          },
          {
            question:
              "Koje godine je Italija prvi put bila domaćin Zimskih olimpijskih igara:",
        
            correctAnswer: "1956.",
            wrongAnswer1: "1960.",
            wrongAnswer2: "2006.",
          },
        
          {
            question:
              "Koje godine je Italija poslednji put (do sada) bila domaćin Zimskih olimpijskih igara:",
        
            wrongAnswer1: "1956.",
            wrongAnswer2: "1960.",
            correctAnswer: "2006.",
          },
          {
            question: "Koje godine se Italija prvi put pojavila na Olimpijskim igrama:",
        
            correctAnswer: "1896.",
            wrongAnswer1: "1904.",
            wrongAnswer2: "1912.",
          },
        
          {
            question: "Prvi filmski festival održan u Italiji je:",
        
            wrongAnswer1: "Milano Film Fest",
            wrongAnswer2: "Torino Film Fest",
            correctAnswer: "Venecijanski festival 1932. godine",
          },
        
          {
            question: "Sva jela od brašna se u Italiji zovu:",
        
            wrongAnswer1: "Hleb",
            wrongAnswer2: "Njoke",
            correctAnswer: "Testenina",
          },
          {
            question:
              "Italija ima jedinu kopnenu granicu na severu, na Alpima, gde se graniči sa sledećim zemljama: Francuskom, Švajcarskom, Austrijom i:",
        
            wrongAnswer1: "Hrvatskom",
            wrongAnswer2: "Bosna i Hercegovina",
            correctAnswer: "Slovenijom",
          },
        
          {
            question: "Najduža reka u Italiji ukupne dužine toka od 652km je:",
        
            wrongAnswer1: "Arno",
            wrongAnswer2: "Tibar",
            correctAnswer: "Po",
          },
          {
            question: "Tri najduže reke u Italiji su:",
        
            correctAnswer: "Adige, Tibar i Po",
            wrongAnswer1: "Piave, Brenta i Adda",
            wrongAnswer2: "Savio, Ticino i Soča",
          },
        
          {
            question: "Prva vespa (model skutera) je proizvedena u:",
        
            correctAnswer: "Italiji 1946. godine",
            wrongAnswer2: "Francuskoj 1946. godine",
            wrongAnswer1: "Engleskoj 1946. Godine",
          },
          {
            question: "Koja država drži Ginisov rekord po broju liftova:",
        
            correctAnswer: "Italija",
            wrongAnswer1: "Španija",
            wrongAnswer2: "Poljska",
          },
        
          {
            question:
              "Italija se može pohvaliti jednim od sedam svetskih čuda novog sveta:",
        
            correctAnswer: "Koloseum u Rimu",
            wrongAnswer1: "Krivi toranj u Pizi",
            wrongAnswer2: "Stari grad Pompeja",
          },
          {
            question:
              "Kojom kampanjom Birra Moretti brend podržava mlade umetnike i umetnost?",
        
            wrongAnswer1: "Birramo umetnika",
            correctAnswer: "Birramo umetnost",
            wrongAnswer2: "Birramo kreativu",
          },
          {
            question: "Glavna poruka Birra Moretti piva je...",
        
            correctAnswer: "Uživanje u malim životnim zadovoljstvima",
            wrongAnswer1: "Uživanje u hrani",
            wrongAnswer2: "Uživanje u piću",
          },
        ],
        qAnswered: false,
        qRand: null,
        answerShuffle: null,

        imageSrc: "",
        crop: { x: 0, y: 0 },
        zoom: 1,
        rotation: 0,

        croppedArea: null,
        croppedAreaPixels: null,

        imageUploaded: null        
    }

    kodRef = React.createRef();

    componentDidMount() {
        ValidatorForm.addValidationRule('isNum', (value) => {
            var patt = /^\d+$/i;
            return patt.test(value);
        });
        ValidatorForm.addValidationRule('isCapitalAlfaNum', (value) => {
            var patt = /^[A-Z0-9]*$/i;
            return patt.test(value);
        });
    }

    componentWillReceiveProps(nextProps) {
        let newState = {
            ...this.state
        }
        if (nextProps.kodStatus !== this.state.kodStatus) {
            if (this.state.activeStep === 2) {
                return;
            }
            newState.kodStatus = nextProps.kodStatus;
            if (newState.kodStatus !== 'KOD_CREATED' && newState.kodStatus !== null) {
                newState.isSubmitted = false;
            }
        }
        if (nextProps.kodId !== this.state.kodId) {
            newState.kodId = nextProps.kodId;
        }
        if (nextProps.kodStatus === 'code image upload success' && this.state.activeStep === 1) {
            newState.imageUploaded = true;
        }        
        this.setState({
            ...this.state,
            ...newState
        });
    }

    componentWillUnmount() {
        if (this.removeAlertTimeout) {
            clearTimeout(this.removeAlertTimeout)
        }
    }

    handleSubmit = () => {
        this.props.onSubmitKod(
            this.state.kod1,
            this.state.kod2,
            this.state.kod3,
            this.props.token);
        this.setState({
            ...this.state,
            isSubmitted: true
        });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        let newState = {
            ...this.state,
            [name]: value.toUpperCase()
        }
        this.setState(newState);
    };

    validatorListener = (result) => {
        let newState = {
            ...this.state,
            isDisabled: !result
        }
        this.setState(newState);
    }

    nextStep = () => {
        const nextStep = this.state.activeStep + 1;

        if (nextStep === 1) {
            this.setState({
                ...this.state,
                activeStep: nextStep,
                kodStatus: null,
            });
        }

        if (nextStep === 2) {
            const qRand = Math.floor(Math.random() * this.state.questions.length);
            let answerShuffle;
            if (typeof (this.state.questions[qRand].wrongAnswer2) !== undefined) {
                answerShuffle = fisherYates([1, 2, 3]);
            } else {
                answerShuffle = fisherYates([1, 2]);
            }
            //console.log('answerShuffle', answerShuffle);
            this.setState({
                ...this.state,
                activeStep: nextStep,
                kodStatus: null,
                qRand,
                answerShuffle
            });
            //console.log(this.removeAlertTimeout)
            if (this.removeAlertTimeout) {
                clearTimeout(this.removeAlertTimeout);
                this.removeAlertTimeout = null;
            }
        }
    }

    answerQuestion = (event) => {
        const value = event.currentTarget.value.split('|')[0];
        const buttonPressed = parseInt(event.currentTarget.value.split('|')[2]);
        const curQ = this.state.questions[this.state.qRand];
        let answer = Object.keys(curQ).find(key => curQ[key] === value);
        //console.log(value, curQ, Object.keys(curQ), answer, typeof(answer), buttonPressed);
        //console.log(curQ);
        let kodStatus;
        if (answer === 'correctAnswer') {
            this.props.onUpdateKodAnswer(this.state.kodId, this.props.token);
            kodStatus = 'KOD_ANSWERED';
            ReactGA.event({
                category: 'Activation',
                action: 'Correct answer'
            });
        } else {
            //this.props.onUpdateKodStatus('KOD_NOT_ANSWERED')
            kodStatus = 'KOD_NOT_ANSWERED';
            ReactGA.event({
                category: 'Activation',
                action: 'Wrong answer'
            });
        }

        this.setState({
            ...this.state,
            kodStatus,
            qAnswered: true,
            buttonPressed: buttonPressed
        });
    }

    forceWrongAnswer = () => {
        //this.props.onUpdateKodStatus('KOD_NOT_ANSWERED_TIME');
        let kodStatus = 'KOD_NOT_ANSWERED_TIME';
        ReactGA.event({
            category: 'Activation',
            action: 'Answer time expired'
        });
        this.setState({
            ...this.state,
            kodStatus,
            qAnswered: true
        });
    }

    resetComponent = () => {
        this.props.onResetKodReducer();
        this.setState({
            ...this.state,
            activeStep: 0,
            kod1: '',
            kod2: '',
            kod3: '',
            // kod: randomString(17, "n"),
            isDisabled: true,
            isSubmitted: false,
            kodStatus: null,
            kodId: null,
            qAnswered: false,
            qRand: null,
            buttonPressed: null,

            imageSrc: "",
            crop: { x: 0, y: 0 },
            zoom: 1,
            rotation: 0,
    
            croppedArea: null,
            croppedAreaPixels: null,
    
            imageUploaded: null
        })
    }


    onCropChange = (crop) => {
        //console.log(crop);
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        // console.log(croppedArea, croppedAreaPixels)

        this.setState({ ...this.state, croppedArea, croppedAreaPixels })
    }

    onZoomChange = (zoom) => {
        //console.log(zoom);
        this.setState({ zoom })
    }

    onFileChange = async (e) => {
        //console.log(e.target.files[0]);
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            let imageDataUrl = await readFile(file)

            // apply rotation if needed
            let orientation = await getOrientation(file)
            .then((res) => {
                //console.log('result', res);
                return res
            })
            .catch((e) => {
                //console.log("error", e);
            })
            //console.log("orient",orientation);
            if (!orientation) {
                orientation = 1;
            }
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }

            this.setState({ ...this.state, imageSrc: imageDataUrl });
        }
    }

    saveCroppedImage = async () => {
        const croppedImage = await getCroppedImg(this.state.imageSrc, this.state.croppedAreaPixels, this.state.rotation);
        // console.log('donee', { croppedImage })
        this.props.kodUploadImage(croppedImage, this.state.kodId, this.props.token)
    }



    fileRef = React.createRef();

    handleClick = event => {
        if (this.state.imageSrc) return
        this.fileRef.current.click();
    };


    render() {

        let alertHolder = null;
        let spinHolder = null;

        if (this.props.spin) {
            spinHolder = (
                <div className={classes.spinner}>
                    <CircularProgress className={classes.icon} mode="indeterminate" size={80} />
                </div>
            )
        }

        if (this.state.kodStatus !== null) {
            switch (this.state.kodStatus) {
                case "KOD_CREATED":
                    alertHolder = <Alert variant="filled" severity="success">Kod je ispravan! Pređite na sledeći korak.</Alert>
                    break;
                case "KOD_EXISTS":
                    alertHolder = <Alert variant="filled" severity="error">Kod je već unesen!</Alert>
                    break;
                case "KOD_NOT_ANSWERED":
                    alertHolder = <Alert variant="filled" severity="error"
                        onClose={() => {
                            this.setState({ ...this.state, kodStatus: 'HIDE' });
                        }}
                    >Na žalost, Vaš odgovor je pogrešan! Kod nije odobren.</Alert>
                    break;
                case "KOD_NOT_ANSWERED_TIME":
                    alertHolder = <Alert variant="filled" severity="error"
                        onClose={() => {
                            this.setState({ ...this.state, kodStatus: 'HIDE' });
                        }}
                    >Na žalost, vreme za odgovor je isteklo! Kod nije odobren.</Alert>
                    break;
                case "KOD_ANSWERED":
                    alertHolder = <Alert variant="filled" severity="success"
                        onClose={() => {
                            this.setState({ ...this.state, kodStatus: 'HIDE' });
                        }}
                    >Vaš odgovor je tačan! Kod je odobren.<br />Poeni će biti registrovani nakon provere koda.</Alert>
                    break;
                case "KOD_UNKNOWN_ERROR":
                    alertHolder = <Alert variant="filled" severity="error">Greška pri potvrđivanju koda! Kontaktirajte nas!</Alert>
                    break;
                case "HIDE":
                    alertHolder = null;
                    break;
                case "code image upload success":
                        alertHolder = <Alert variant="filled" severity="success">Fotografija računa je uspešno poslata. Pređite na sledeći korak.</Alert>
                        break;                        
                default:
                    alertHolder = <Alert variant="filled" severity="warning">Greška pri unosu koda!</Alert>
            }
            if (!this.removeAlertTimeout) {
                //console.log("TIMEOUT SET!!!");
                this.removeAlertTimeout = setTimeout(function () {
                    if (this.removeAlertTimeout) {
                        clearTimeout(this.removeAlertTimeout);
                    }
                    this.props.onUpdateKodStatus(null);
                    //console.log("TIMEOUT!!!!!!")
                }.bind(this), 5000);
            }
        }

        let kvizContainer = null;

        if (this.state.activeStep === 1) {
            kvizContainer = (
                <>
                    {
                        this.state.imageUploaded
                            ?
                            null
                            :
                            <div className={classes.cropHolder} onClick={this.handleClick}>
                                <ReceiptIcon className={classes.photoIcon} />
                                <h4><br/>Pritisnite ovde za slanje</h4>
                                {this.state.imageSrc
                                    ? (
                                        <CodePhoto
                                            image={this.state.imageSrc}
                                            crop={this.state.crop}
                                            zoom={this.state.zoom}
                                            aspect={window.innerWidth / window.innerHeight}
                                            onCropChange={this.onCropChange}
                                            onCropComplete={this.onCropComplete}
                                            onZoomChange={this.onZoomChange}
                                            rotation={this.state.rotation}
                                            objectFit="contain"
                                        />
                                    )
                                    : null
                                }
                                {/* <img src={this.state.preview} alt="Preview" /> */}
                                {this.state.imageSrc && !this.state.imageUploaded
                                    ? (
                                        <div className={classes.cancelButton}>
                                            <IconButton
                                                color="secondary"
                                                aria-label="Obrišite sliku"
                                                onClick={() => {
                                                    this.setState({ ...this.state, imageSrc: "" })
                                                }}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </div>
                                    )
                                    : null
                                }

                            </div>
                    }
                    <div className={classes.inputImage}>
                        <input type="file" onChange={this.onFileChange} accept="image/*" ref={this.fileRef} />
                    </div>
                    {
                        this.state.imageUploaded
                            ?
                            <>
                            <br/>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={(this.nextStep)}
                                >
                                Sledeći korak
                            </Button>
                            <br/>
                            </>
                            :
                            this.state.imageSrc
                                ? (
                                    <div className={classes.buttonsHolder}>
                                        <IconButton onClick={
                                            () => {
                                                let newRotation = this.state.rotation - 90;
                                                if (newRotation < 0) {
                                                    newRotation = 270;
                                                }
                                                this.setState({ ...this.state, rotation: newRotation })
                                            }
                                        }>
                                            <RotateLeftIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={
                                                () => {
                                                    let newRotation = this.state.rotation + 90;
                                                    if (newRotation > 360) {
                                                        newRotation = 90;
                                                    }
                                                    this.setState({ ...this.state, rotation: newRotation })
                                                }
                                            }
                                        >
                                            <RotateRightIcon />
                                        </IconButton>
                                        <IconButton
                                            disabled={this.state.zoom >= 3 ? true : false}
                                            onClick={
                                                () => {
                                                    let newZoom = this.state.zoom + 0.25;
                                                    this.setState({ ...this.state, zoom: newZoom })
                                                }
                                            }
                                        >
                                            <ZoomInIcon />
                                        </IconButton>
                                        <IconButton
                                            disabled={this.state.zoom <= 1 ? true : false}
                                            onClick={
                                                () => {
                                                    let newZoom = this.state.zoom - 0.25;
                                                    this.setState({ ...this.state, zoom: newZoom })
                                                }
                                            }
                                        >
                                            <ZoomOutIcon />
                                        </IconButton>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            aria-label="Snimite sliku"
                                            disabled={this.state.imageSrc ? false : true}
                                            onClick={this.saveCroppedImage}
                                            className={classes.saveImageIcon}
                                        >
                                            <span style={{ marginRight: "1rem" }}>
                                                Snimite sliku
                                            </span>
                                            <Save />
                                        </Button>
                                    </div>
                                ) : null
                    }
                </>
            )
        }
        
        if (this.state.activeStep === 2) {
            const q = this.state.questions[this.state.qRand];
            //console.log(q.wrongAnswer2);
            kvizContainer = (
                <Hoc>
                    <h4>{q.question}</h4>
                    <div className={classes.answerButtons}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.answerbutton + ' ' + classes['sh' + this.state.answerShuffle[0]] + ' ' + (this.state.buttonPressed===1 ? classes.pressedCorrect : null)}
                            onClick={(e) => this.answerQuestion(e)}
                            disabled={this.state.qAnswered}
                            value={[q.correctAnswer, this.state.qRand, 1].join("|")}
                        >
                            {q.correctAnswer}
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.answerbutton + ' ' + classes['sh' + this.state.answerShuffle[1]] + ' ' + (this.state.buttonPressed===2 ? classes.pressedWrong : null)}
                            onClick={(e) => this.answerQuestion(e)}
                            disabled={this.state.qAnswered}
                            value={[q.wrongAnswer1, this.state.qRand, 2].join("|")}
                        >
                            {q.wrongAnswer1}
                        </Button>
                        {(typeof (q.wrongAnswer2) !== 'undefined')
                            ? <Button
                                variant="outlined"
                                color="primary"
                                className={classes.answerbutton + ' ' + classes['sh' + this.state.answerShuffle[2]] + ' ' + (this.state.buttonPressed===3 ? classes.pressedWrong: null)}
                                onClick={(e) => this.answerQuestion(e)}
                                disabled={this.state.qAnswered}
                                value={[q.wrongAnswer2, this.state.qRand, 3].join("|")}
                            >
                                {q.wrongAnswer2}
                            </Button>
                        : null
                        }

                    </div>
                    {
                        !this.state.qAnswered
                            ? <Countdown wrong={this.forceWrongAnswer} q={this.state.qRand} />
                            : null
                    }
                    {
                        this.state.kodStatus !== null
                            ? (this.state.kodStatus === 'KOD_ANSWERED' ||
                                this.state.kodStatus === 'KOD_NOT_ANSWERED' ||
                                this.state.kodStatus === 'KOD_NOT_ANSWERED_TIME' ||
                                this.state.kodStatus === 'HIDE')
                                ? <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.resetButton}
                                    onClick={() => this.resetComponent()}
                                >
                                    Unesite novi kod
                                    </Button>
                                : null
                            : null
                    }
                </Hoc>
            )
        }

        let moduleArr = [
            (<Hoc>
                <h3>Unesite PFR broj računa</h3>
                <p>PFR broj se sastoji iz tri dela razdvojenih crticom (-).<br/>
                Delove koda unesite u svako od predviđenih polja.</p>
                <ValidatorForm
                    ref="form"
                    onSubmit={this.handleSubmit}
                    onError={errors => console.log(errors)}
                    className={classes.form}
                >
                        <div className={classes.inputHolder}>
                    <TextValidator
                        ref={this.kodRef}
                        className={classes.textField}
                        autoComplete="off"
                        label={"prvi deo"}
                        name="kod1"
                        value={this.state.kod1}
                        disabled={this.props.spin ? true : this.state.isSubmitted}
                        onChange={this.handleChange}
                        validators={['required', 'isCapitalAlfaNum']}
                        errorMessages={[
                            'Polje je obavezno',
                            'Uneti karakteri nisu velika slova abecede ili brojevi'
                        ]}
                        validatorListener={this.validatorListener}
                    />
                    <span>-</span>
                    <TextValidator
                        ref={this.kodRef}
                        className={classes.textField}
                        autoComplete="off"
                        label={"drugi deo"}
                        name="kod2"
                        value={this.state.kod2}
                        disabled={this.props.spin ? true : this.state.isSubmitted}
                        onChange={this.handleChange}
                        validators={['required', 'isCapitalAlfaNum']}
                        errorMessages={[
                            'Polje je obavezno',
                            'Uneti karakteri nisu velika slova abecede ili brojevi'
                        ]}
                        validatorListener={this.validatorListener}
                    />
                    <span>-</span>
                    <TextValidator
                        ref={this.kodRef}
                        className={classes.textField}
                        autoComplete="off"
                        label={"treći deo"}
                        name="kod3"
                        value={this.state.kod3}
                        disabled={this.props.spin ? true : this.state.isSubmitted}
                        onChange={this.handleChange}
                        validators={['required', 'isNum']}
                        type={"number"}
                        errorMessages={[
                            'Polje je obavezno',
                            'Uneti karakteri nisu brojevi'
                        ]}
                        validatorListener={this.validatorListener}
                    />
                    </div>

                    <div className={classes.buttonHolder}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            type='submit'
                            disabled={this.state.isSubmitted ? true : false
                            }
                        >
                            Pošaljite kod
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={(this.nextStep)}
                            disabled={this.state.kodId === null ? true : false}
                        >
                            Sledeći korak
                        </Button>
                    </div>
                </ValidatorForm>
            </Hoc>),
            (<Hoc>
                <h3 style={{ textAlign: 'center', marginTop: "0rem" }}>Pošaljite fotografiju računa</h3>
                {this.state.imageUploaded
                ?
                <><br/><br/></>
                :
                <p style={{ textAlign: 'center' }}>Obratite pažnju da na fotografiji bude jasno vidljiv ceo račun kao i PFR broj računa</p>
                }
                
                {kvizContainer}
            </Hoc>),            
            (<Hoc>
                <h3>Odgovorite na pitanje</h3>
                <p style={{ textAlign: 'center' }}>Tačan odgovor potvrđuje vaš kod.</p>
                {kvizContainer}
            </Hoc>)
        ]

        let content = (
            <Hoc>
                <h3 className={classes.notLoggedIn}>Prijavite se</h3>
                <p style={{ textAlign: 'center' }}>Da bi ste učestvovali,<br /> molimo Vas da se prijavite na svoj profil.</p>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => { this.props.onUpdateModal(true, 'login') }}
                >

                    <span className={classes.buttonLabel}>
                        Prijava
                    </span>
                </Button>
                <p style={{ textAlign: "center", maxWidth:"500px", margin:"2rem 5%"}}>
                  Prilikom svake prijave na stranici <nobr>„Moj profil“</nobr> moći ćete da vidite na kojoj ste poziciji na Rang listi, ukupan broj osvojenih bodova kao i koliko bodova ima prvi sledeći bolje plasirani Učesnik na Rang listi.
                </p>

            </Hoc>
        );

        if (this.props.isAuthenticated) {
            content = (
                <Hoc>
                    <div className={classes.stepperHolder}>
                        <Paper className={classes.module}>
                            {moduleArr[this.state.activeStep]}
                            <div className={classes.alertHolder}>
                                {alertHolder}
                            </div>
                            {spinHolder}
                        </Paper>
                        <Stepper activeStep={this.state.activeStep}>
                            {
                                this.state.steps.map((label, ind) => {
                                    return (
                                        <Step key={ind}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    )
                                })
                            }
                        </Stepper>
                    </div>
                </Hoc>
            )
        }

        
        content = (
            <Hoc>
                <h3 className={classes.finishedTxt} style={{textAlign:"center"}}>Nagradni konkurs je završen.</h3>
                <p style={{ textAlign: 'center' }}>Hvala na učestvovanju!</p>
            </Hoc>
        );
        
        


        return (
            <Hoc>
                <div className={classes.container}>
                    <section id="container">
                        <div className={classes.moduleHolder}>
                            {content}
                        </div>
                    </section>
                </div>
            </Hoc>
        );
    }
};


const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        userID: state.auth.userId,
        spin: state.kod.loading,
        token: state.auth.token,
        kodStatus: state.kod.kodStatus,
        kodId: state.kod.kodId
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateModal: (modState, modWhat) => dispatch(actions.changeModal(modState, modWhat)),
        onResetKodReducer: () => dispatch(actions.resetKodReucer()),
        onSubmitKod: (kod1, kod2, kod3,  token) => dispatch(actions.kodUpload(kod1, kod2, kod3, token)),
        onUpdateKodStatus: (status) => dispatch(actions.kodStatus(status)),
        onUpdateKodAnswer: (kodId, token) => dispatch(actions.kodUpdateAnswer(kodId, token)),
        kodUploadImage: (imgData, kodId, tok) => dispatch(actions.kodUploadImage(imgData, kodId, tok)),
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Activation));
