import * as actionTypes from './actionTypes';
import axiosSetup from '../../axios-setup';

export const kodLoading = (loadingstate) => {
    return {
        type: actionTypes.KOD_LOADING_STATE,
        loadingstate
    };
};

export const kodStatus = (newStatus) => {
    return {
        type: actionTypes.KOD_STATUS,
        status: newStatus,
    };
}

export const kodId = (id) => {
    return {
        type: actionTypes.KOD_ID,
        kodid: id
    };
}

export const resetKodReucer = () => {
    return {
        type: actionTypes.KOD_RESET
    }
}

export const kodUpdateAnswer = (kodId, authToken) => {
    axiosSetup.defaults.headers.common = { 'Authorization': "Bearer " + authToken };
    const url = '/kod';
    return dispatch => {
        axiosSetup.patch(url, {kodId})
            .then( res => {
                if (res.data) {
                    if (res.data.message === "code update success") {
                        dispatch(kodStatus("KOD_ANSWERED"))
                    } else {
                        dispatch(kodStatus("KOD_UNKNOWN_ERROR"));
                    }
                }
            })
            .catch( e => {
                console.log(e.response.data.error)
                dispatch(kodStatus("KOD_UNKNOWN_ERROR"));
            });
    }
}

export const kodUpload = (kod1, kod2, kod3, authToken) => {
    axiosSetup.defaults.headers.common = { 'Authorization': "Bearer " + authToken };
    const url = '/kod';
    return dispatch => {
        dispatch(kodLoading(true));
        axiosSetup.post(url, { kod1, kod2, kod3 })
            .then(res => {
                if (res.data) {
                    if (res.data.message === "KOD_CREATED") {
                        dispatch(kodStatus(res.data.message));
                        dispatch(kodId(res.data.id));
                    } else {
                        dispatch(kodStatus('KOD_CREATE_FAILED'));
                    }
                } else {
                    dispatch(kodStatus('KOD_CREATE_RETURN_NO_DATA'));
                }
            })
            .catch(e => {
                if (e.response.data.error.includes('duplicate key error')) {
                    dispatch(kodStatus('KOD_EXISTS'));
                } else if (e.response.data.error.includes('Code length not 18')) {
                    dispatch(kodStatus('KOD_NOT18'));
                } else {
                    dispatch(kodStatus('UNKNOWN_KOD_ERROR', null));
                }
            })
    }
}

export const kodUploadImage = (imgData,kodId,authToken) => {
    axiosSetup.defaults.headers.common = { 'Authorization': "Bearer " + authToken };

	return dispatch => {
		dispatch(kodLoading(true));
        
		let formData = new FormData(); 
		formData.append("file", imgData); // storageRef = storage.ref(uid).child('avatar.png');
        formData.append("kodId", kodId)
		axiosSetup.defaults.headers.common = {
            'Authorization': "Bearer " + authToken,
			'Content-Type': 'multipart/form-data'
		};
		
		axiosSetup.post('/kodimage', formData)
		.then( res => {
            //console.log(res.data);
            if (res.data.message==="code image upload success") {
                dispatch(kodStatus(res.data.message));
            }
            dispatch(kodLoading(false));
			return true;
		})
		.catch( err => {
            dispatch(kodLoading(false));
            dispatch(kodStatus('IMAGE_UPLOAD_FAILED', null));
			console.log(err);
		})
	}
}