import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    kodStatus: null,
    kodId: null,
    loading: false
};

const kodLoadingState = (state, action) => {
    return updateObject (state, {
        loading: action.loadingstate
    })
}

const kodStatus = (state, action) => {
    return updateObject (state, {
        loading: false,
        kodStatus: action.status
    })
}

const kodId = (state, action) => {
    return updateObject (state, {
        kodId: action.kodid
    })
}

const kodReset = (state, action) => {
    return updateObject (state, {
        ...initialState
    })
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.KOD_LOADING_STATE: return kodLoadingState(state, action);
        case actionTypes.KOD_STATUS: return kodStatus(state, action);
        case actionTypes.KOD_ID: return kodId(state, action);
        case actionTypes.KOD_RESET: return kodReset(state, action);
        default:
            return state;
    }
};

export default reducer;
